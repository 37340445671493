import React, { useEffect, useState } from "react";
import { Container, Row, Stack } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { Dimmer, Divider, Icon, Label, Loader } from "semantic-ui-react";
import ProductCard from "../../component/ProductCard";
import { useMyGlobal } from "../../component/GlobalData";
import axios from "axios";
import TimerCountDown from "../../component/TimerCountDown";

const FlashsaleProductPage = () => {
    const dataProduct = useLocation().state
    const [isLoading, setIsLoading] = useState(true)
    const [noData, setNoData] = useState(false)
    const { token, fsData } = useMyGlobal()
    const idCustomer = (localStorage.getItem('loginData')) ? JSON.parse(localStorage.getItem('loginData')).id : null
    const [productsFs, setProductsFs] = useState([])
    const [fsDataDirect, setFsDataDirect] = useState([])
    const [currentTime, setCurrentTime] = useState(1)

    const getFSProd = async (id_fs) => {
        const param = {
            id_fs: id_fs,
            id_customer: idCustomer
        }

        await axios({
            method: "POST",
            url: `${global.SRV}api_hoops/controler/event.php/?index=1`,
            headers: {
                'Content-Type': 'text/plain',
                'Authorization': token
            },
            data: param
        })
            .then(res => {
                if (res.data.state === 200) {
                    if (res.data.data.length > 0 && currentTime > 0) {
                        setProductsFs(res.data.data)
                        setIsLoading(false)
                    } else {
                        setIsLoading(false)
                    }
                }
            })
    }

    const getActiveFlashsale = async () => {
        await axios({
            method: "GET",
            url: `${global.SRV}api_hoops/controler/event.php/?index=6`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': token
            }
        })
            .then(res => {
                setFsDataDirect(res.data);
                getFSProd(res.data[0].id_fs)
            })
    }

    useEffect(() => {
        if (!dataProduct){ 
            getActiveFlashsale()
        } else {
            setIsLoading(false)
        }
        window.scrollTo(0, 0)
    }, [])

   
    return (
        <Container style={{ minHeight: '65vh', marginTop: '15vh' }}>
            {
                fsData.length > 0 || fsDataDirect.length > 0 ?
                    <>
                        <div className="text-uppercase fs-3 fw-bold">{(fsData.length > 0 && fsData[0].name) || (fsDataDirect.length > 0 && fsDataDirect[0].name)}</div>
                        <Row className="text-start">
                            <Stack direction="vertical" gap={1} >
                                <Divider horizontal>{(fsData.length > 0 && fsData[0].description) || (fsDataDirect.length > 0 ? fsDataDirect[0].description : 'Grab it soon before it runs out')}</Divider>
                                <div><span className="me-3">Will end on</span>
                                    <span className="fw-bold bg-orange">
                                        <Label color="orange">
                                            <TimerCountDown expiryTime={(fsData.length > 0 && fsData[0].date_end) || (fsDataDirect.length > 0 && fsDataDirect[0].date_end)} setCurentTime={setCurrentTime} sendTime={true} />
                                        </Label>
                                    </span>
                                </div>
                            </Stack>
                        </Row>
                        <Row className="d-flex justify-content-start mb-3 mt-3">
                            <Dimmer active={isLoading} inverted><Loader active={isLoading}>Loading</Loader></Dimmer>
                            <div style={noData ? { display: 'block' } : { display: 'none' }}>No product</div>
                            {
                                dataProduct ?
                                dataProduct.map((item, i) =>
                                    <ProductCard data={item} key={i} option="fsPage" />
                                )
                                :
                                productsFs.map((item, i) =>
                                    <ProductCard data={item} key={i} option="fsPage" />
                                )
                            }
                        </Row>
                    </>
                    :
                    <div>
                        <Icon name="warning circle" className="mb-3 fs-3" /><br />
                        <>There are no flashsale events</>
                    </div>
            }
        </Container>
    )
}

export default FlashsaleProductPage;