import React, { createContext, useContext, useEffect, useState } from 'react';
import axios from 'axios';

const MyContext = createContext();

export const GlobalData = ({ children }) => {
  const [userData, setUserData] = useState(null);
  const [fsData, setFsData] = useState([])
  const [wishData, setWishData] = useState([])
  const [token, setToken] = useState('9c98dh92db9ejn');
  const idCustomer = (localStorage.getItem('loginData')) ? JSON.parse(localStorage.getItem('loginData')).id : null
  const [categoryData, setCategoryData] = useState([])
  const [categoryDataNav, setCategoryDataNav] = useState([])
  const [categoryAll, setCategoryAll] = useState([])
  const [isPortrait, setIsPortrait] = useState(window.matchMedia("(orientation: portrait)").matches)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [included, setIncluded] = useState([])
  
  const getActiveFlashsale = async () => {
    await axios({
      method: "GET",
      url: `${global.SRV}api_hoops/controler/event.php/?index=6`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': token
      }
    })
    .then(res => { 
      setFsData(res.data);
    })
  }

  // const getFlashsale = async (idFs) => {
  //   const param = {
  //     id_fs: idFs
  //   }

  //   try {
  //     const res = await axios.post(`${global.SRV}api_hoops/controler/event.php/?index=4`, param, {
  //       headers: {
  //         'Content-Type': 'application/json',
  //         'Authorization': token // Ensure token is defined or passed to the component
  //       }
  //     });

  //     if (res.data.state === 200) { 
  //       setFsData(res.data.data);
  //       console.log(res.data.data)
  //     }
  //   } catch (error) {
  //     // Handle errors, e.g., log the error or show a notification
  //     console.error('Error fetching flashsale data:', error);
  //     // Jangan atur isFlashsaleCalled menjadi true jika ada kesalahan
  //     return;
  //   } finally {
  //     // Set isFlashsaleCalled to true after the call is completed, only if there's no error
  //   }
  // }
  
  const userToken = async (id_customer) => {
    await axios({
      method: "POST",
      url: `${global.SRV}api_hoops/controler/customer.php?index=13`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': token
      },
      params: {
        "id_customer":id_customer
      }
    }).then (res => {
      if (res.data.status === 200){
        if (res.data.token === null){
          setToken('9c98dh92db9ejn')
        } else {
          setToken(res.data.token)
        }
      }
    })
  }
  
  const getIsWish = async (id_customer) => {
    const param = new URLSearchParams()
    param.append('id_customer', id_customer)
    await axios({
      method: "POST",
      url: `${global.ERP}api_web/controler/product.php?index=7`,
      // url: "https://hoopsindonesia.co.id/api_hoops/controler/product.php?index=7",
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': token
      },
      data: param
    })
      .then(res => {
        if (res.data.state === 200) {
          setWishData(res.data.data)
        }
      })
  }

  const getCatParentChild = async () => {
    const param = new URLSearchParams()
    param.append('option', 'parentChild')

    await axios({
      method: "POST",
      url: `${global.ERP}api_web/controler/category.php/?index=1`,
      // url: "https://hoopsindonesia.co.id/api_hoops/controler/category.php/?index=1",
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': token
      },
      data: param
    })
      .then((response) => { 
        if (response.data.state === 200) {
          setCategoryData(response.data.data.filter(i => i.on_page == 1))
          setCategoryDataNav(response.data.data.filter(i => i.on_navigation == 1))
          setCategoryAll(response.data.data)
        }
      })
  }

  const getVoucherProduct = async () => {
    await axios({
        method: "GET",
        url: "https://hoopsindonesia.co.id/api_hoops/controler/voucher.php?index=3",
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': token
        },
    })
        .then(res => {
            if (res.data.state === 200) {
                setIncluded(res.data.data)
            }
        })
}


  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };

  }, []);

  useEffect(() => {
      userToken(idCustomer)
      getActiveFlashsale()
      getIsWish(idCustomer)
      getCatParentChild()
      getVoucherProduct()
  }, [idCustomer])



  return (
    <MyContext.Provider value={{
      userData,
      setUserData,
      token,
      fsData,
      wishData,
      categoryData,
      categoryDataNav,
      categoryAll,
      windowWidth,
      setWindowWidth,
      isPortrait,
      setIsPortrait,
      included,
      setIncluded,
      idCustomer
    }}>
      {children}
    </MyContext.Provider>
  );
};

export const useMyGlobal = () => {
  return useContext(MyContext);
};