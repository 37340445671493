import React from "react";
import { useState } from "react";
import { Card, Col, Container, Dropdown, DropdownButton, ListGroup, Modal, Row, Stack, Spinner, Alert, Image } from "react-bootstrap";
import { Button, Dimmer, Divider, Icon, ItemMeta, Label, Loader, Popup } from "semantic-ui-react";
import SummaryCart from "../../component/SummaryCart";
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import axios from "axios";
import AddressForm from "../../component/AddressForm";
import { useLocation, useNavigate } from "react-router-dom"
import TimerCountDown from "../../component/TimerCountDown";
import { HiListBullet, HiOutlineBanknotes, HiOutlineCreditCard } from "react-icons/hi2";
import { useMyGlobal } from "../../component/GlobalData";
import { Toast } from "../../component/Toast";
import { formatToK } from "../../component/utility";

const Checkout = ({ cart, setCart, updateNotif, pageLoader }) => { 
    const navigate = useNavigate();
    const ses = window.localStorage
    const [userAddress, setUserAddress] = useState(null)
    const [selectedAddress, setSelectedAddress] = useState(null)
    const [selectedAddressEdit, setSelectedAddressEdit] = useState(null)
    const userProfile = JSON.parse(ses.getItem('profile'))
    const [selKurir, setSelKurir] = useState(null)
    const [modalService, setModalService] = useState(false)
    const [service, setService] = useState(null)
    const [modalPayment, setModalPayment] = useState(false)
    const [courierLoader, setCourierLoader] = useState(false)
    const [paymentSelect, setPaymentSelect] = useState({
        id: '003',
        type: 'Snap Payment (more payment options here)',
        name: 'Multi payment method',
        is_active: 'Y',
        logo: <div style={{ borderRadius: 5, padding: '5px 10px 5px 10px', backgroundColor: 'orange' }}><HiListBullet size={28} /></div>
    })
    const urlGetService = 'http://apiv2.jne.co.id:10102/tracing/api/pricedev'
    const [modalAddress, setModalAddress] = useState(false)
    const [modalAddressEdit, setModalAddressEdit] = useState(false)
    const [modalAddressList, setModalAddressList] = useState(false)
    const [origin, setOrigin] = useState("BDO10054")
    const [courierServices, setCourierServices] = useState(null)
    const [courier, setCourier] = useState(null)
    const [selCompleteCourier, setSelCompleteCourier] = useState(null)
    const [addressList, setAddressList] = useState(null)
    const idCustomer = (localStorage.getItem('loginData')) ? JSON.parse(localStorage.getItem('loginData')).id : null
    const [modalLoading, setModalLoading] = useState(false)
    const [paymentRes, setPaymentRes] = useState('')
    const [loadingMsg, setLoadingMsg] = useState('')
    const [payAlert, setPayAlert] = useState(false)
    const [currentTime, setCurrentTime] = useState(null)
    const [orderAddress, setOrderAddress] = useState(null)
    const [orderCourier, setOrderCourier] = useState(null)
    const [addressState, setAddressState] = useState({})
    const [courierOptLabel, setCourierOptLabel] = useState('Available Courier')
    const { token, windowWidth } = useMyGlobal()
    const [addrChange, setAddrChange] = useState(false)
    const [priceJNECalled, setPriceJNECalled] = useState(false)
    const [btnCclState, setBtnCclState] = useState(false)
    const [isLoad, setIsLoad] = useState(false)
    const [cancelLoad, setCancelLoad] = useState(false)
    const jneAsuransiAdmin = 5000
    const jneAsuransiPersen = 0.2

    const payment = {
        data: [
            {
                id: '001',
                type: 'Bank Transfer',
                name: 'BCA',
                is_active: 'N',
                logo: <div style={{ borderRadius: 5, padding: '5px 10px 5px 10px', backgroundColor: 'lightgray' }}><HiOutlineBanknotes size={28} /></div>
            },
            {
                id: '002',
                type: 'Card Payment',
                name: 'Credit Card',
                is_active: 'N',
                logo: <div style={{ borderRadius: 5, padding: '5px 10px 5px 10px', backgroundColor: 'lightgray' }}><HiOutlineCreditCard size={28} /></div>
            },
            {
                id: '003',
                type: 'Snap Payment (more payment options here)',
                name: 'Multi payment method',
                is_active: 'Y',
                logo: <div style={{ borderRadius: 5, padding: '5px 10px 5px 10px', backgroundColor: 'orange' }}><HiListBullet size={28} /></div>
            },
        ]
    }

    const percentTag = {
        alignItems: 'center',
        background: 'linear-gradient(to right, #cc3b21, #FFA500)',
        fontSize: '0.714286rem',
        color: 'rgb(255, 255, 255)',
        fontWeight: 600,
        fontSize: '0.714286rem',
        borderRadius: '0 0 5px 0px',
    }

    const percentTagNormal = {
        alignItems: 'center',
        background: 'linear-gradient(to right, grey, lightgrey)',
        fontSize: '0.714286rem',
        color: 'rgb(255, 255, 255)',
        fontWeight: 600,
        fontSize: '0.714286rem',
        borderRadius: '0 0 5px 0px',
    }

    const showPayAlert = () => (setPayAlert(true))
    const hidePayAlert = () => (setPayAlert(false))

    const loadingShow = () => (setModalLoading(false))
    const loadingClose = () => (setModalLoading(true))

    const handleClose = () => (setModalService(false))
    const handleShow = () => (setModalService(true))

    const closePay = () => (setModalPayment(false))
    const showPay = () => (setModalPayment(true))

    const closeAddressForm = () => (setModalAddress(false))
    const showAddressForm = () => (setModalAddress(true))

    const closeAddressFormEdit = () => (setModalAddressEdit(false))
    const showAddressFormEdit = () => (setModalAddressEdit(true))

    const closeAddressList = () => (setModalAddressList(false))
    const showAddressList = () => (setModalAddressList(true))

    const setUsedAddress = (list, idAddr) => {
        const addr = list && list.filter(i => i.id_address == idAddr)[0]
        // sessionStorage.setItem('address', JSON.stringify(addr))
        setUserAddress(addr)
        setSelectedAddress(addr)
        cart.customer.address = addr
        setCart(cart)
    }

    const updateQtyInc = () => {
        let param = cart.products

        axios({
            method: "POST",
            url: `${global.SRV}api_hoops/controler/order.php/?index=15`,
            headers: {
                'Content-Type': 'text/plain',
                'Authorization': token
            },
            data: param
        })
            .then(res => {

            })
    }

    const handleCancel = (idCust) => {
        const param = new URLSearchParams()
        const idC = cart.summary.id_cart
        const idO = cart.summary.order_id

        param.append('id_customer', idCust)
        param.append('id_cart', idC)
        param.append('status', 'CANCEL BEFORE PAYMENT')
        param.append('status_code', '3')
        param.append('id_order', idO)
        let msg = "Are you sure want to cancel this order?"
        if (window.confirm(msg) === true) {
            setCancelLoad(true)
            axios({
                method: "POST",
                url: `${global.SRV}api_hoops/controler/order.php/?index=6`,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': token
                },
                data: param
            })
                .then(response => {
                    if (response.data.state == 200) {
                        setBtnCclState(true)
                        if (!btnCclState) {
                            updateQtyInc() // BALIKIN QTY PRODUK
                            cartProduct(idCust)
                            updateNotif(idCust)
                            navigate('/')
                            Toast.fire({
                                icon: 'success',
                                title: 'This order has been canceled...'
                            })
                            setCancelLoad(false)
                        }
                    } else {
                        alert(response.data.message)
                        setCancelLoad(false)
                    }
                })
        } else {
            setCancelLoad(false)
        }
    }

    const handleClickDropdown = (item, cart) => {
        if (cart.products.length === 0) {
            alert('there are no products in the cart')
        } else if (!cart.customer.address) {
            alert('there are no address selected, Please add address before selecting courier')
        } else {
            setSelKurir(item);
            courierPriceJNE(cart.summary.total_weight, cart.customer.address ? cart.customer.address.cost_code : userAddress.cost_code, item.id_courier)
            // localStorage.setItem('selKurir', JSON.stringify(item))
            // setService(item.name[0])
            // localStorage.setItem('service', JSON.stringify(item.service[0]))
            // localStorage.setItem('kurir', JSON.stringify(item))
            handleShow()
        }
    }

    const handleClickService = (item, cart) => {
        setService(item)
        setSelCompleteCourier({
            courier: selKurir,
            service: item
        })
        // handleClose();
        updateOrderCourier(
            cart.summary.order_id, {
            courier: selKurir,
            service: item
        },
            selectedAddress)
        cart.summary.kurir = (selKurir) && selKurir.id_courier;
        cart.summary.service = item.service_code;
        cartProduct(idCustomer)
    }

    // const SelectedCourier = ({ kurir, selService }) => {
    //     if (kurir && selService) {
    //         setCourierOptLabel('Change Courier')
    //         return (
    //             <section className="mt-2">
    //                 <div className="fw-bold">Selected Courier</div>
    //                 <div className="text-muted">{kurir.name}</div>
    //                 <div className="fw-bold">Service</div>
    //                 <div className="text-muted d-flex justify-content-between">
    //                     {(cart.summary.voucher_ongkir && cart.summary.voucher_ongkir > 0 && selService.price > cart.summary.voucher_ongkir) ?
    //                         <span>{(selService) ? selService.service_display : <></>} &nbsp;&nbsp; {(selService) ? global.IDR.format(selService.price - cart.summary.voucher_ongkir) : <></>}</span>
    //                         :
    //                         (cart.summary.voucher_ongkir > 0 && selService.price < cart.summary.voucher_ongkir) ?
    //                             <span>{(selService) ? selService.service_display : <></>} &nbsp;&nbsp; {(selService) ? global.IDR.format(selService.price - selService.price) : <></>}</span>
    //                             :
    //                             <span>{(selService) ? selService.service_display : <></>} &nbsp;&nbsp; {(selService) ? global.IDR.format(selService.price) : <></>}</span>
    //                     }
    //                     {/* <span className="fw-bold" onClick={handleShow} style={{ cursor: 'pointer' }}>Change</span> */}
    //                 </div>
    //             </section>
    //         )
    //     } else {
    //         const fkurir = orderCourier
    //         setCourierOptLabel('Available Courier')
    //         if (fkurir) {

    //             return (
    //                 <section className="mt-2">
    //                     <div className="fw-bold">Selected Courier</div>
    //                     <div className="text-muted">{fkurir.name}</div>
    //                     <div className="fw-bold">Service</div>
    //                     <div className="text-muted d-flex justify-content-between">
    //                         {
    //                             <span>{fkurir.service_display} &nbsp;&nbsp; Rp {fkurir.courier_cost}</span>
    //                         }

    //                         {/* <span className="fw-bold" onClick={() => alert('ssss')} style={{ cursor: 'pointer' }}>Change</span> */}
    //                     </div>
    //                 </section>
    //             )
    //         } else {
    //             <></>
    //         }
    //     }
    // }

    useEffect(() => {
        if (cart.summary.kurir !== null) {
            setCourierOptLabel('Change Courier')
        } else if (cart.summary.kurir == null) {
            setCourierOptLabel('Available Courier')
        }
    }, [selKurir, cart])

    const setDefaultAddress = (idAddress, status) => {
        const param = new URLSearchParams();

        param.append('id_address', idAddress);
        param.append('status', status)

        axios({
            method: "POST",
            url: `${global.SRV}api_hoops/controler/customer.php/?index=4`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': token
            },
            data: param
        })
            .then((response) => {
                if (response.data.state === 200) {
                    getCustomerAddress(idCustomer)
                }
            })
    }


    const getActiveCourier = async () => {
        await axios({
            method: "GET",
            url: `${global.SRV}api_hoops/controler/courier.php/?index=2`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': token
            },
        })
            .then((response) => {
                if (response.data.length > 0) {
                    if (response.data !== courier) {
                        setCourier(response.data)
                    }
                    // setSelKurir(response.data.filter(i => i.id_courier == cart.summary.kurir)[0])
                } else {
                    setCourier(null)
                    // setSelKurir(null)
                }
            })
    }

    const getCustomerAddress = async (idCustomer) => {
        const param = new URLSearchParams()
        param.append('id_customer', idCustomer)

        await axios({
            method: "POST",
            url: `${global.SRV}api_hoops/controler/customer.php/?index=3`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': token
            },
            data: param
        })
            .then((response) => {
                if (response.data.state === 200) {
                    setAddressList(response.data.data)
                    // setOrigin(response.data.data.filter(i => i.isDefault == 1)[0].cost_code)
                    if (cart.customer.address !== null) {
                        if (!orderAddress) {
                            setUserAddress(cart.customer.address)
                        } else {
                            setUserAddress(orderAddress)
                        }
                    } else if (cart.customer.address === null) {
                        if (!orderAddress) {
                            setUserAddress(response.data.data.filter(i => i.isDefault == 1)[0])
                        } else {
                            setUserAddress(orderAddress)
                        }
                    }
                }
            })
    }

    const clearKurir = (id_order) => {
        const param = new URLSearchParams()
        param.append("id_order", id_order)

        axios({
            method: "POST",
            url: `${global.SRV}api_hoops/controler/order.php/?index=9`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': token
            },
            data: param
        })
            .then(response => {

            })
    }

    const updateOrderAddress = (orderId, address) => {
        if (orderId && address) {
            const param = new URLSearchParams()
            param.append('id_order', orderId)
            param.append('id_address', address)

            axios({
                method: "POST",
                url: `${global.SRV}api_hoops/controler/order.php/?index=2`,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': token
                },
                data: param
            })
                .then(response => {
                    clearKurir(orderId)
                    cart.summary.kurir = null
                    cart.summary.service = null
                    cart.summary.service_cost = 0
                    cart.summary.total_order = (cart.summary.totalPrice + cart.summary.service_cost) - cart.summary.voucher
                    setService(null)
                    setSelCompleteCourier(null)
                    setCart(cart)
                })
        } else {
            Toast.fire({
                icon: 'warning',
                title: 'Parameter not completed'
            })
        }
    }


    const updateOrderCourier = (orderId, courier, address) => {
        if (orderId !== null && courier !== null && address !== null) {
            const asuransi = Math.ceil(((cart.summary.totalPrice * jneAsuransiPersen) / 100)) + jneAsuransiAdmin
            const param = new URLSearchParams()
            param.append('id_order', orderId)
            param.append('id_courier', (courier.courier) ? courier.courier.id_courier : courier.id_courier)

            if (cart.summary.voucher_ongkir && cart.summary.voucher_ongkir > 0 && courier.service.price > cart.summary.voucher_ongkir) {
                param.append('courier_cost', courier.service.price - cart.summary.voucher_ongkir)
            } else if (cart.summary.voucher_ongkir > 0 && courier.service.price < cart.summary.voucher_ongkir) {
                param.append('courier_cost', courier.service.price - courier.service.price)
            } else {
                param.append('courier_cost', courier.service.price)
            }

            param.append('courier_service', courier.service.service_code)
            param.append('id_address', address.id_address)
            param.append('asuransi', asuransi)
            axios({
                method: "POST",
                url: `${global.SRV}api_hoops/controler/order.php/?index=2`,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': token
                },
                data: param
            })
                .then(response => {

                })
        } else {
            Toast.fire({
                icon: 'warning',
                title: 'Parameter not fillfiled'
            })
            setSelCompleteCourier(null)
        }
    }

    const SpinerGrow = () => {
        return <Spinner animation="grow" />;
    }

    const createPaymentCC = (customer) => {
        const orderId = cart.summary.order_id

        navigate('/checkout/paymentCard', {
            state: {
                order_id: orderId,
                customer_id: customer
            }
        })
    }

    const createSnapPay = async (customer) => {
        const param_snap = new URLSearchParams()
        param_snap.append('id_customer', customer)
        setIsLoad(true)

        const updateStateCartSnap = () => {
            const param = new URLSearchParams()
            param.append('id_order', cart.summary.order_id)
            param.append('id_cart', cart.summary.id_cart)
            param.append('total_voucher', cart.summary.voucher)
            param.append('state', 3)
            axios({
                method: "POST",
                url: `${global.SRV}api_hoops/controler/order.php/?index=4`,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': token
                },
                data: param
            })
                .then(response => {
                    if (response.data.state == 200) {
                        cart.is_ordered = response.data.data // SET STATUS CART JADI 3
                        setCart(cart)
                    }
                })
        }

        if (customer) {
            await axios({
                method: "POST",
                url: `${global.SRV}api_hoops/controler/payment.php/?index=10`,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': token
                },
                data: param_snap
            })
                .then(res => {
                    if (res.data.token) {
                        if (res.data.redirect_url) {
                            updateStateCartSnap()
                            window.location.replace(res.data.redirect_url)
                            setIsLoad(false)
                        } else {
                            Toast.fire({
                                icon: "error",
                                text: "Redirect url not found, contact Customer Service",
                                position: "center"
                            })
                        }
                    } else {
                        Toast.fire({
                            icon: "error",
                            text: res.data.error_messages,
                            position: 'center'
                        })
                    }
                })
        } else {
            Toast.fire({
                icon: 'error',
                text: "You'r not login or id customer not identify, try to relogin",
                position: "center"
            })
        }
    }


    const createPayment = (customer) => {

        const param = new URLSearchParams()
        const param2 = new URLSearchParams()

        param.append('id_order', cart.summary.order_id)
        param.append('id_cart', cart.summary.id_cart)
        param.append('total_voucher', cart.summary.voucher)
        param.append('state', 2)
        param2.append('id_customer', customer)

        setModalLoading(true)
        setLoadingMsg('Please wait, the payment process is loaded')
        closePay()

        const updateStateCart = () => {
            axios({
                method: "POST",
                url: `${global.SRV}api_hoops/controler/order.php/?index=4`,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': token
                },
                data: param
            })
                .then(response => {
                    if (response.data.state == 200) {
                        cart.is_ordered = response.data.data // SET STATUS CART JADI 2
                        setCart(cart)
                    }
                })
        }

        const orderConfirmationMail = (payment) => {
            // let param = new URLSearchParams();


            // param.append("to", cart.customer.email)
            // param.append("to_name", cart.customer.first_name + ' ' + cart.customer.last_name)
            // param.append("subject", "Order Confirmation")
            // param.append("content", cart) // DIKIRIM SELALU JADI STRING DI PHP
            // param.append("page", '../email/orderInfo.html')
            cart.courier_detail = orderCourier
            cart.payment = payment
            const param = {
                to: cart.customer.email,
                to_name: cart.customer.first_name + ' ' + cart.customer.last_name,
                subject: "Order Confirmation",
                content: cart,
                page: '../email/orderInfo.html'
            }

            axios({
                method: "POST",
                url: `${global.SRV}api_hoops/controler/email.php/?index=2`,
                headers: {
                    'Content-Type': 'text/plain',
                    'Authorization': token
                },
                data: param
            }).then(res => {

            })
        }

        // CREATE PAYMENT
        axios({
            method: "POST",
            url: `${global.SRV}api_hoops/controler/payment.php/?index=1`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': token
            },
            data: param2
        })
            .then(response => {

                if (response.data) {
                    setModalLoading(false)
                    if (response.data) {
                        if (response.data.response_api.status_code == 201) {
                            updateStateCart()
                            updateNotif(idCustomer)
                            setPayAlert(false)
                            // orderConfirmationMail(response.data.response_api) UDAH ADA EMAIL DARI MIDTRANS
                            navigate('/cart/checkout/payment',
                                {
                                    state: { 'summary': cart.summary.order, 'response': response.data.response_api }
                                }
                            )
                        } else {
                            setPaymentRes(response.data.response_api.status_message)
                            setPayAlert(true)
                        }
                    }
                }
            })

    }

    const cartProduct = async (idCustomer) => {
        const param = new URLSearchParams()
        param.append('id_customer', idCustomer)

        await axios({
            method: "POST",
            url: `${global.SRV}api_hoops/controler/cart.php/?index=5`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': token
            },
            data: param
        })
            .then((response) => {
                if (response.data.state == 200) {
                    setCart(response.data.data)
                }
            })

    }


    useEffect(() => {
        const validateAddingAddress = async (idCust) => {
            const param = new URLSearchParams()
            param.append('id_customer', idCust)

            await axios({
                method: "POST",
                url: `${global.SRV}api_hoops/controler/customer.php/?index=10`,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': token
                },
                data: param
            })
                .then(res => {
                    if (res.data.state === 200) {
                        setAddressState(res.data.data)
                    }
                })
        }

        if (cart.customer.id_customer || idCustomer) {
            getCustomerAddress(idCustomer || cart.customer.id_customer)
            validateAddingAddress(idCustomer || cart.customer.id_customer)
        } else {
            navigate('/')
        }
    }, [cart, idCustomer])

    const courierPriceJNE = async (wg, thru, idCourier) => {
        if (parseFloat(wg) > 0){
            setCourierLoader(true)
            const param = new URLSearchParams()
            param.append('weight', parseFloat(wg))
            param.append('from', origin)
            param.append('thru', thru)
            param.append('id_courier', idCourier)
            await axios({
                method: "POST",
                url: `${global.SRV}api_hoops/controler/courier.php/?index=5`,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': token
                },
                data: param
            })
                .then(response => {
                    if (response.data.status !== false) {
                        if (response.data !== courierServices) {
                            setPriceJNECalled(true)
                            setCourierServices(response.data);
                        }
                        setCourierLoader(false)
                    }
                })
        } else {
            alert(`Unaccepted weight ${wg}, try to reload this page.`)
        }
    }

    const getOrderAddress = async (idOrder) => {
        const param = new URLSearchParams
        param.append('id_order', idOrder)

        await axios({
            method: "POST",
            url: `${global.SRV}api_hoops/controler/order.php/?index=10`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': token
            },
            data: param
        })
            .then(response => {
                if (response.data.state === 200) {
                    if (orderAddress !== response.data.data) {
                        setOrderAddress(response.data.data)
                    }
                }
            })
    }

    const getOrderKurir = async (idOrder) => {
        const param = new URLSearchParams()
        param.append('id_order', idOrder)

        await axios({
            method: "POST",
            url: `${global.SRV}api_hoops/controler/order.php/?index=11`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': token
            },
            data: param
        })
            .then(response => {
                if (response.data.state === 200) {
                    if (orderCourier !== response.data.data) {
                        setOrderCourier(response.data.data)
                    }
                }
            })
    }



    const calcDiscPercent = (item) => {
        if (item.discount.id_discount && !item.fs_on) {
            return <Label size="mini" style={percentTag}>{Math.ceil(item.discount.discount_value)}%</Label>
        } else if (item.discount == null && item.fs_on) {
            return <Label size='mini' style={percentTag}>{Math.ceil(item.fs_discount)}%</Label>
        } else if (item.discount !== null && item.fs_on) {
            return <Label size='mini' style={percentTag}>{Math.ceil(item.fs_discount)}%</Label>
        } else if (item.fs_on) {
            return <Label size='mini' style={percentTag}>{Math.ceil(item.fs_discount)}%</Label>
        } else if (item.fs_on && item.is_discounted == '1') {
            return <Label size='mini' style={percentTag}>{Math.ceil(item.fs_discount)}%</Label>
        } else if (item.discount == null && !item.fs_on && item.is_discounted == '0') {
            return 0
        } else if (!item.fs_on && item.is_discounted == '0') {
            return <Label size='mini' style={percentTagNormal}>No Dscn.</Label>
        } else {
            return undefined
        }

    }

    const calcDiscValue = (item) => {
        if (item.discount.id_discount && !item.fs_on) {
            return global.IDR.format(item.discount.discount_total)
        } else if (item.discount == null && item.fs_on) {
            return item.fs_discount_value ? global.IDR.format(item.fs_discount_value) : global.IDR.format((item.price * item.fs_discount) / 100)
        } else if (item.discount !== null && item.fs_on) {
            return item.fs_discount_value ? global.IDR.format(item.fs_discount_value) : global.IDR.format((item.price * item.fs_discount)) / 100
        } else if (item.fs_on) {
            return item.fs_discount_value ? global.IDR.format(item.fs_discount_value) : global.IDR.format((item.price * item.fs_discount)) / 100
        } else if (!item.fs_on && item.is_discounted == '0') {
            return global.IDR.format(0)
        } else if (item.discount == null && !item.fs_on && item.is_discounted == '0') {
            return 0
        } else if (item.discount == null && !item.fs_on) {
            return 0
        } else {
            return undefined
        }
    }

    const priceCoret = (item) => {
        if (item.fs_on && item.is_discounted == '1') {
            return <s>{global.IDR.format(item.price)}</s>
        } else if (item.discount.dicount_value > 0) {
            return <s>{global.IDR.format(item.price)}</s>
        } else if (item.fs_on && item.is_discounted == '0') {
            return <s>{global.IDR.format(item.price)}</s>
        } else if (!item.fs_on && item.is_discounted == 1 && item.discount.dicount_value == 0) {
            return ''
        }
    }

    const LabelVoucher = () => {
        if (service) {
            if (cart.summary.voucher_ongkir > 0 && service.price > cart.summary.voucher_ongkir) {
                return "-" + global.IDR.format(cart.summary.voucher_ongkir)
            } else {
                if (cart.selected_voucher.length > 0) {
                    const voucher = cart.selected_voucher.find(v => v.type === 'O' || v.discount_type === 'O')
                    const voucherName = (voucher && voucher.name) || null
                    const voucherNominal = (voucher && voucher.nominal) || null
                    if (voucherName && voucherNominal) {
                        return (
                            <Popup
                                position="bottom center"
                                content="You have applied this voucher"
                                trigger={
                                    <Label tag size="small" color="orange">{voucherName + ' ' + formatToK(voucherNominal)}</Label>
                                }
                            />
                        )
                    }
                }
            }
        }
    }

    const handleClickProduct = (item) => {
        navigate(`/product/${item.product_id}`)
    }
   
    useEffect(() => {
        if (cart.customer.address !== null) {
            if (parseFloat(cart.summary.total_weight) > 0) {
                if (!priceJNECalled) {
                    let total_weight = 0
                    total_weight = cart.products.reduce((total, item) => total + parseInt(item),0)
                    courierPriceJNE(cart.summary.total_weight, cart.customer.address.cost_code, selKurir ? selKurir.id_courier : cart.summary.kurir)
                }
            } else {
                Toast.fire({
                    icon: "warning",
                    text: `Weight of item can't be : ` + parseInt(cart.summary.total_weight),
                    position: "center"
                })
                setSelCompleteCourier(null)
                setSelKurir(null)
                setUserAddress(cart.customer.address)
                // localStorage.removeItem('service')
            }
        } else if (userAddress) {
            if (parseFloat(cart.summary.total_weight) > 0) {
                courierPriceJNE(cart.summary.total_weight, userAddress.cost_code, selKurir ? selKurir.id_courier : cart.summary.kurir)
            }

        }
    }, [cart, userAddress])

    useEffect(() => {
        if (!orderAddress || addrChange) {
            setAddrChange(false)
            getOrderAddress(cart.summary.order_id)
        }
        getActiveCourier();
        if (orderAddress) {
            setUsedAddress(addressList, orderAddress.id_address)
        } else if (userAddress) {
            setUsedAddress(addressList, userAddress.id_address)
        }
    }, [userAddress, cart, orderAddress, addrChange])

    useEffect(() => {
        getOrderKurir(cart.summary.order_id);
    }, [cart, orderAddress, addrChange])

    useEffect(() => {
        if (courierServices) {
            setSelCompleteCourier({
                courier: (selKurir) ? selKurir : orderCourier,
                service: courierServices.price.filter(i => i.service_code === cart.summary.service)[0]
            })
            setService(courierServices.price.filter(i => i.service_code === cart.summary.service)[0])
        }
    }, [courierServices, selKurir])

    useEffect(() => { // CANCEL IF EXPIRE TIME HAS MEET
        const cancelOnExpire = (idCus) => {
            const param = new URLSearchParams()
            const idC = cart.summary.id_cart
            const idO = cart.summary.order_id

            param.append('id_customer', idCus)
            param.append('id_cart', idC)
            param.append('status', 'ORDER TIME EXPIRED')
            param.append('status_code', '9')
            param.append('id_order', idO)
            param.append('timer', currentTime)

            axios({
                method: "POST",
                url: `${global.SRV}api_hoops/controler/order.php/?index=6`,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',

                },
                data: param
            })
                .then(response => {
                    if (response.data.state == 200) {
                        updateQtyInc() // BALIKIN QTY PRODUK
                        cartProduct(idCus)
                        navigate("/checkout/orderExpirePage", { state: cart })
                        setCart(cart)
                        updateNotif(idCus)
                    }
                })
        }

        if (currentTime !== null && currentTime == 0 && typeof currentTime != 'undefined') {
            try {
                cancelOnExpire(idCustomer)
                // cartProduct(idCustomer)
            } catch (e) { console.log(e) }
        }

    }, [])
  
    // const cancelOnExpire = (idCus) => {
    //     const param = new URLSearchParams()
    //     const idC = cart.summary.id_cart
    //     const idO = cart.summary.order_id

    //     param.append('id_customer', idCus)
    //     param.append('id_cart', idC)
    //     param.append('status', 'ORDER TIME EXPIRED')
    //     param.append('status_code', '9')
    //     param.append('id_order', idO)

    //     axios({
    //         method: "POST",
    //         url: `${global.SRV}api_hoops/controler/order.php/?index=6`,
    //         headers: {
    //             'Content-Type': 'application/x-www-form-urlencoded',
    //             'Authorization': token
    //         },
    //         data: param
    //     })
    //         .then(response => {
    //             if (response.data.state == 200) {
    //                 updateQtyInc() // BALIKIN QTY PRODUK
    //                 cartProduct(idCus)
    //                 // navigate('/')
    //                 setCart(cart)
    //                 updateNotif(idCus)
    //                 navigate("/checkout/orderExpirePage", { state: cart })
    //             }
    //         })
    // }

    const handleHideCourier = () => {
        if (courierServices.price.length > 0) {
            if (typeof selCompleteCourier.service === 'object') {
                if (selCompleteCourier.courier && selCompleteCourier.service) {
                    handleClose()
                } else {
                    Toast.fire({
                        icon: 'error',
                        text: 'Please choose one of the couriers first...'
                    })
                }
            } else {
                Toast.fire({
                    icon: 'error',
                    text: 'Please choose one of the couriers first...'
                })
            }
        } else {
            alert("There is no courier to the address you chose, please choose another address...")
            handleClose()
        }
        // (selCompleteCourier) ? (selCompleteCourier.courier && selCompleteCourier.service) ? handleClose : null : null
    }

    useEffect(() => {
        if (cart.is_ordered == '0') {
            alert("This order has been expired...")
            navigate('/')
        }
    }, [cart.id_ordered])
    console.log(cart.summary)
    return (
        <section>
            {(cart) ? (
                <Container fluid='sm' style={{ marginTop: '4em' }}>
                    <Dimmer active={pageLoader} inverted>
                        <Loader inverted>Loading</Loader>
                    </Dimmer>
                    <Modal show={modalLoading} backdrop='static' keyboard={false} centered>
                        <Modal.Body>
                            <Row className="p-5">
                                <Col md={12} className="d-flex justify-content-center align-self-middle">
                                    <div className="mb-3"><SpinerGrow /></div>
                                </Col>
                                <Col md={12} className="d-flex justify-content-center align-self-middle">
                                    <div className="mb-3 fs-5">{loadingMsg}</div>
                                </Col>
                            </Row>
                        </Modal.Body>
                    </Modal>
                    <Modal show={modalAddressEdit} onHide={closeAddressFormEdit} backdrop='static' keyboard='false' centered>
                        <Modal.Header closeButton>Edit Shipping Address</Modal.Header>
                        <Modal.Body>
                            <AddressForm
                                showModal={setModalAddressEdit}
                                getUserAddress={getCustomerAddress}
                                isFirstAddress={false}
                                editData={selectedAddressEdit}
                                setUserAddress={setUserAddress}
                            />
                        </Modal.Body>
                    </Modal>
                    <Modal show={modalAddressList} onHide={closeAddressList} size='lg' backdrop='static' keyboard={false}>
                        <Modal.Header closeButton>Your Address List</Modal.Header>
                        <Modal.Body className="ps-4 pe-4">
                            {
                                (addressList && selectedAddress) ? (
                                    addressList.map((item, i) => (
                                        <section className="callout" key={i} style={(item.id_address === selectedAddress.id_address) ? { backgroundColor: '#f6f6f6' } : { backgroundColor: '#ffff' }}>
                                            <Row className="d-flex justify-content-start align-items-middle">
                                                <Col lg={10} md={10} sm={10}>
                                                    <span className="fw-bold me-3">{item.address_label}</span>
                                                    <span>{(item.isDefault == 1) ? <Label>Default</Label> : <></>}</span>
                                                    <div>{item.recipient_name}</div>
                                                    <div>{item.celular_number}</div>
                                                    <div>{item.address_detail}</div>
                                                    <div>{item.destination}</div>
                                                    <Button.Group basic className="mt-3">
                                                        <Button
                                                            content="Edit Address"
                                                            onClick={() => {
                                                                setSelectedAddressEdit(item)
                                                                if (setSelectedAddressEdit !== null) {
                                                                    showAddressFormEdit();
                                                                    closeAddressList()
                                                                }
                                                            }}
                                                        />
                                                        {(item.isDefault == 1) ? <></> :
                                                            <Button
                                                                content="Make Default"
                                                                onClick={() => setDefaultAddress(item.id_address, (item.isDefault == 0) ? 1 : 0)} />}
                                                    </Button.Group>
                                                </Col>
                                                <Col lg={2} md={2} sm={2} className="d-flex justify-content-end align-self-center">
                                                    {(item.id_address === selectedAddress.id_address) ?
                                                        <FontAwesomeIcon className="text-muted fs-5" icon={faCheck} /> :
                                                        <Button content="Select"
                                                            onClick={() => {
                                                                setUsedAddress(addressList, item.id_address)
                                                                closeAddressList()
                                                                updateOrderAddress(cart.summary.order_id, item.id_address)
                                                                setAddrChange(true)
                                                            }} />}
                                                </Col>
                                            </Row>
                                        </section>
                                    ))
                                ) : <>No data</>
                            }
                            <Row>
                                <Col>
                                    {
                                        addressState.allow ?
                                            <Button
                                                content="Add New Address"
                                                fluid
                                                basic
                                                onClick={() => {
                                                    showAddressForm()
                                                    closeAddressList()
                                                }} />
                                            :
                                            <div className="text-muted border p-2 text-center small rounded"><i>Can't add more address, limited {addressState.limit} addresses per customer</i></div>
                                    }
                                </Col>
                            </Row>
                        </Modal.Body>
                    </Modal>
                    <Modal show={modalService} onHide={() => handleHideCourier()} backdrop='static' keyboard={false} centered>
                        <Modal.Header closeButton className="fs-5 fw-bold">Select Courier Services</Modal.Header>
                        <Modal.Body style={{ backgroundColor: 'whitesmoke' }}>
                            <Dimmer active={courierLoader} inverted>
                                <Loader inverted>Loading</Loader>
                            </Dimmer>
                            <Row className="mb-3">
                                <Col
                                    style={{
                                        backgroundColor: 'white',
                                        paddingTop: 10,
                                        paddingBottom: 10,
                                        borderRadius: 4,
                                        marginRight: 10,
                                        marginLeft: 10
                                    }}
                                >
                                    <Stack direction="horizontal" gap={2}>
                                        <div style={{ backgroundColor: 'whitesmoke', borderRadius: 4, border: 'solid 1px lightgrey', padding: '5px' }}>
                                            <img src={(selKurir) ? selKurir.logo : <></>} width={32} height={32}></img>
                                        </div>
                                        <div>
                                            <div className="fw-bold">{(selKurir) ? selKurir.name : (orderCourier) ? orderCourier.name : <></>}</div>
                                            <div>{(selKurir) ? selKurir.description : (orderCourier) ? orderCourier.description : <></>}</div>
                                        </div>
                                    </Stack>
                                </Col>
                            </Row>
                            <Row>
                                <Col
                                    style={{
                                        backgroundColor: '#fff',
                                        marginRight: 10,
                                        marginLeft: 10,
                                        borderRadius: 4
                                    }}
                                >
                                    <ListGroup variant="flush">
                                        {
                                            (courierServices) ?
                                                courierServices.price.map((item, i) => (
                                                    <ListGroup.Item
                                                        onClick={() => handleClickService(item, cart)}
                                                        key={i}
                                                    >
                                                        <Row style={{ cursor: 'pointer' }}>
                                                            <Col sm={11}>
                                                                <div className="fw-bold">{item.service_display}</div>
                                                                <div className="me-2">
                                                                    {(cart.summary.voucher_ongkir > 0 && item.price > cart.summary.voucher_ongkir) ?
                                                                        <Stack direction="horizontal" gap={3}>
                                                                            <div className="text-muted">Estimated {item.etd_from} - {item.etd_thru} day</div>
                                                                            {(item.price > cart.summary.voucher_ongkir) ? global.IDR.format(item.price - cart.summary.voucher_ongkir) : global.IDR.format(item.price)} <del>{global.IDR.format(item.price)}</del>
                                                                        </Stack> :
                                                                        (cart.summary.voucher_ongkir > 0 && item.price < cart.summary.voucher_ongkir) ?
                                                                            <Stack direction="horizontal" gap={2}>
                                                                                <div className="text-muted">Estimated {item.etd_from} - {item.etd_thru} day</div>
                                                                                <div>{global.IDR.format(item.price - item.price)} &nbsp; <del>{global.IDR.format(item.price)}</del></div>
                                                                            </Stack>
                                                                            :
                                                                            <Stack direction="horizontal" gap={2}>
                                                                                <div className="text-muted">Estimated {item.etd_from} - {item.etd_thru} day</div>
                                                                                <div>{global.IDR.format(item.price)}</div>
                                                                            </Stack>

                                                                    }
                                                                </div>
                                                                {/* <span className="text-muted">Estimated {item.etd_thru} day</span> */}
                                                            </Col>
                                                            <Col sm={1} className="text-end d-flex align-content-center flex-wrap fs-5 justify-content-md-center" >
                                                                {
                                                                    (service) ? (
                                                                        (item.service_code === service.service_code) ? <FontAwesomeIcon className="text-muted" icon={faCheck} /> : <FontAwesomeIcon className="text-light" icon={faCheck} />
                                                                    ) : <></>
                                                                }
                                                            </Col>
                                                        </Row>
                                                    </ListGroup.Item>
                                                )) : <div className="text-center m-3"><Icon name="spinner" loading /></div>
                                        }
                                    </ListGroup>
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button content="Apply" onClick={() => handleClose()} color="black" />
                        </Modal.Footer>
                    </Modal>
                    <Modal show={modalAddress} onHide={closeAddressForm} backdrop='static' keyboard={false} centered >
                        <Modal.Header closeButton>Add Address</Modal.Header>
                        <Modal.Body>
                            <AddressForm showModal={setModalAddress} getUserAddress={getCustomerAddress} isFirstAddress={true} />
                        </Modal.Body>
                    </Modal>
                    <Modal show={modalPayment} onHide={closePay} backdrop='static' keyboard={false} centered style={{ backgroundColor: 'rgb(0,10,20,0.7)' }}>
                        <Modal.Header closeButton className="fs-5 fw-bold">Payment</Modal.Header>
                        <Modal.Body className="ps-0 pe-0" style={{ backgroundColor: 'whitesmoke' }}>
                            <Row className="ms-3 me-3 pt-2 pb-2 mb-3 rounded" style={{ backgroundColor: '#ffff' }}>
                                <Col lg={12} md={12} sm={12} xs={12}>
                                    <div className="fw-bold">Payment Method</div>
                                    <ListGroup variant='flush'>
                                        {
                                            payment.data.map((item, i) => (
                                                item.is_active == 'Y' ?
                                                    <ListGroup.Item
                                                        onClick={() => {
                                                            setPaymentSelect(item)
                                                            localStorage.setItem('payment', JSON.stringify(item))
                                                        }}
                                                        key={i}
                                                    >
                                                        <Row style={{ cursor: 'pointer' }}>
                                                            <Col sm={11} xs={10}>
                                                                <Stack direction="horizontal" gap={2}>
                                                                    <div>{item.logo}</div>
                                                                    <div>
                                                                        <div className="fw-bold">{item.name}</div>
                                                                        <div className="text-muted">{item.type}</div>
                                                                    </div>
                                                                </Stack>
                                                            </Col>
                                                            <Col sm={1} xs={2} className="text-end d-flex align-content-center flex-wrap fs-5">
                                                                {
                                                                    (paymentSelect !== null) ? (
                                                                        (paymentSelect.id === item.id) ? <FontAwesomeIcon className="text-muted" icon={faCheck} /> : <FontAwesomeIcon className="text-light" icon={faCheck} />
                                                                    ) : <></>
                                                                }
                                                            </Col>
                                                        </Row>
                                                    </ListGroup.Item>
                                                    : <></>
                                            ))
                                        }
                                    </ListGroup>
                                </Col>
                            </Row>
                            <Row className="ms-3 me-3 pt-2 pb-2 rounded" style={{ backgroundColor: '#ffff' }}>
                                <div className="fw-bold mb-2">Payment Summary</div>
                                <Col sm={8} xs={8} className="text-muted">
                                    <div>Transaction Totals ({(cart.summary.totalItem) ? cart.summary.totalItem : 0} item)</div>
                                    {(cart.summary.voucher > 0) ? <div>Voucher </div> : <></>}
                                    <div>Shipping Cost - {(selKurir) ? selKurir.name : (orderCourier) && orderCourier.name}&nbsp;{(service) ? service.service_display : <></>}</div>
                                    {(service) ? cart.summary.voucher_ongkir > 0 && service.price > cart.summary.voucher_ongkir ? <>Shipping Disc.</> : <></> : <></>}
                                    <div className="text-muted">Shipping Insurance</div>
                                </Col>
                                <Col sm={4} xs={4} className="text-dark">
                                    <div className="text-end">{global.IDR.format(cart.summary.totalPrice)}</div>
                                    {(cart.summary.voucher > 0) ? <div className="text-end">{global.IDR.format(cart.summary.voucher)}</div> : <></>}
                                    <div className="text-end">{(service) ? service.price >= cart.summary.voucher_ongkir ? global.IDR.format(service.price) : <del>{global.IDR.format(service.price)}</del> : 'Rp 0'}</div>
                                    <div className="text-end mb-2">{global.IDR.format(cart.summary.asuransi)}</div>

                                </Col>
                                <div className="text-end"><LabelVoucher /></div>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer className="ps-0 pe-0">
                            <Row className="w-100">
                                <Col sm={5} xs={5} className="mb-2">
                                    <div className="">Total Bill</div>
                                    <div className="fs-5 fw-bold">{(cart.summary.total_order) ? global.IDR.format(parseFloat(cart.summary.total_order)) : global.IDR.format(0)}</div>
                                </Col>
                                <Col sm={7} xs={7} className="d-flex justify-content-center">
                                    <Button
                                        color="black"
                                        icon="arrow alternate circle right outline"
                                        labelPosition="right"
                                        fluid
                                        loading={isLoad}
                                        content="Pay Now"
                                        onClick={() => {
                                            if (!paymentSelect) {
                                                alert('No payment method selected')
                                            } else {
                                                if (paymentSelect.id === '001') {
                                                    createPayment(idCustomer)
                                                } else if (paymentSelect.id === '002') {
                                                    createPaymentCC(idCustomer)
                                                } else if (paymentSelect.id === '003') {
                                                    createSnapPay(idCustomer)
                                                }
                                            }
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Modal.Footer>
                    </Modal>
                    <Row className="pb-4 text-start" style={{ marginTop: '7%' }}>
                        <Col>
                            <Alert variant="secondary" show={payAlert} onClose={hidePayAlert} dismissible>
                                {paymentRes}
                            </Alert>
                        </Col>
                        <div className="mt-5 mb-2">
                            <div className="me-2 mb-2 fs-5 fw-bold">Checkout</div>
                            {
                                (cart.summary.expiry_time) &&
                                <div className="text-muted fw-bold">
                                    <span>Finish your order on </span>
                                    <span style={{ color: '#FF5733' }}>
                                        <TimerCountDown
                                            expiryTime={new Date(cart.summary.expiry_time)}
                                            sendTime={true}
                                            setCurentTime={setCurrentTime}
                                        // cancelOnExpire={cancelOnExpire}
                                        />
                                    </span>
                                </div>
                            }
                        </div>

                        <Col lg={8} md={8} sm={12} className="mb-4">
                            <Card className="mb-4">
                                {/* <Card.Header><h4>Shipping Address</h4></Card.Header> */}
                                <Card.Body>
                                    <h4><Icon name="map marker alternate" color="orange" />&nbsp;Shipping Address</h4>
                                    {(userAddress) ?
                                        <section>
                                            <div className="fw-bold mb-3"><Label color="orange" size="mini" tag> {userAddress.address_label} </Label></div>
                                            <div className="fw-bold">{userAddress.recipient_name}</div>
                                            <div>{userAddress.celular_number}</div>
                                            <div className="text-muted">{userAddress.address_detail}</div>
                                            <div className="">{userAddress.destination}</div>
                                            <hr />
                                            <Button color="black" icon="map marker alternate" content='Other Address' onClick={() => showAddressList()} />
                                        </section>

                                        :
                                        <>
                                            <Button color="orange" content="Add Address" onClick={() => showAddressForm()} />
                                        </>}

                                </Card.Body>
                            </Card>
                            <Card>
                                {/* <Card.Header><h4>Products</h4></Card.Header> */}
                                <Card.Body>
                                    <Row>
                                        <Col lg={8} md={8} sm={12}>
                                            <h4><Icon name="box" color="orange" />&nbsp;Products</h4>
                                            <ListGroup variant="flush" numbered>
                                                {
                                                    cart.products.map((item, i) => {
                                                        return (
                                                            <ListGroup.Item as="li" key={i} className="d-flex justify-content-start align-items-start">
                                                                {
                                                                    <section className="d-flex justify-content-start align-items-start">
                                                                        <div style={{ width: 100, height: 90 }} className="ms-2 me-3">
                                                                            <Image
                                                                                src={item.product_image ? item.product_image.image : "https://via.placeholder.com/500x400.png"}
                                                                                alt={item.product_name}
                                                                                height={90}
                                                                                width={110}
                                                                                rounded
                                                                                className="border"
                                                                            />
                                                                        </div>
                                                                        <div className="ms-3">
                                                                            <div className="fw-bold text-wrap mb-2" style={{ cursor: 'pointer', color: '#FF5733' }} onClick={() => handleClickProduct(item)}>{item.product_name}</div>
                                                                            <div>{item.id_product}</div>
                                                                            <div className="mb-2">
                                                                                <span className="me-3 fw-bold text-truncate">{global.IDR.format(item.final_price)}&nbsp;&nbsp;(x{item.qty})</span>
                                                                                <span className="text-muted">{priceCoret(item)}</span>
                                                                            </div>
                                                                            <div className="mb-2">
                                                                                <>{calcDiscPercent(item)}</>
                                                                                {/* <span><small>{calcDiscValue(item)}</small></span> */}
                                                                            </div>
                                                                            <div>
                                                                                {
                                                                                    item.included_on_voucher &&
                                                                                    item.included_on_voucher.map((item, i) => (
                                                                                        <Popup trigger={
                                                                                            <div className='fw-bold' style={{ color: '#F7634C', fontSize: '0.8em' }}>
                                                                                                <Icon name='tag' />
                                                                                                {item.name} -{item.type == 'D' ? item.percent : item.nominal}% off
                                                                                            </div>
                                                                                        }
                                                                                            size='mini'
                                                                                            content={`This item is included in the voucher ${item.name}`}
                                                                                        />
                                                                                    ))
                                                                                }
                                                                            </div>
                                                                            <div>
                                                                                <span className='me-3 text-muted'>Weight : {item.product_dimension.weight} kg</span>
                                                                                <span className='me-3 text-muted'>Size : {item.selected_size}</span>
                                                                                <span className='me-3 text-muted'>Color :</span>
                                                                                <span>
                                                                                    <canvas className='border-start border-top border-bottom border-dark' width={10} height={10} style={{ backgroundColor: item.selected_color.color_main }} key={1} />
                                                                                    <canvas className='border-top border-bottom border-dark' width={10} height={10} style={{ backgroundColor: item.selected_color.color_secondary }} key={2} />
                                                                                    <canvas className='border-end border-top border-bottom border-dark' width={10} height={10} style={{ backgroundColor: item.selected_color.color_accent }} key={3} />
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </section>
                                                                }
                                                            </ListGroup.Item>
                                                        )
                                                    })
                                                }
                                            </ListGroup>
                                        </Col>
                                        <Col>
                                            <h4><Icon name="shipping fast" color="orange" />&nbsp;Delivery</h4>
                                            <Popup
                                                size="mini"
                                                position={cart.customer.address !== null ? "top right" : "bottom left"}
                                                content={cart.customer.address !== null ? "Select some courier" : <div style={{ color: '#da7d13' }}>No address selected..!</div>}
                                                trigger={
                                                    <DropdownButton
                                                        flip
                                                        variant="outline-dark"
                                                        className={cart.customer.address !== null ? "d-grid" : "d-grid shaked"}
                                                        title={courierOptLabel}
                                                        disabled={cart.customer.address == null ? true : false}
                                                    >
                                                        {
                                                            (courier !== null) ? (
                                                                courier.map((item, i) => (
                                                                    <Dropdown.Item
                                                                        eventKey={i}
                                                                        key={i}
                                                                        onClick={() => handleClickDropdown(item, cart)}
                                                                    >
                                                                        <div className="fw-bold" style={{ color: '#FF5733' }}>{item.name}</div>
                                                                        <div className="text-muted">{item.description}</div>
                                                                    </Dropdown.Item>
                                                                ))
                                                            ) : (<div className="text-center" style={{ color: '#FF5733' }}>No Courier</div>)

                                                        }
                                                    </DropdownButton>
                                                } />
                                            <section>
                                                {/* <SelectedCourier kurir={selKurir} selService={service} /> */}
                                                {
                                                    (selKurir && service) ?
                                                        <section className="mt-2">
                                                            <div className="fw-bold">Selected Courier</div>
                                                            <div style={{ color: '#FF5733' }}>{selKurir.name}</div>
                                                            <div className="fw-bold">Service</div>
                                                            <div className="d-flex justify-content-between" style={{ color: '#FF5733' }}>
                                                                {(cart.summary.voucher_ongkir && cart.summary.voucher_ongkir > 0 && service.price > cart.summary.voucher_ongkir) ?
                                                                    <span>{(service) ? service.service_display : <></>} &nbsp;&nbsp; {(service) ? global.IDR.format(service.price - cart.summary.voucher_ongkir) : <></>}</span>
                                                                    :
                                                                    (cart.summary.voucher_ongkir > 0 && service.price < cart.summary.voucher_ongkir) ?
                                                                        <span>{(service) ? service.service_display : <></>} &nbsp;&nbsp; {(service) ? global.IDR.format(service.price - service.price) : <></>}</span>
                                                                        :
                                                                        <span>{(service) ? service.service_display : <></>} &nbsp;&nbsp; {(service) ? global.IDR.format(service.price) : <></>}</span>
                                                                }
                                                                {/* <span className="fw-bold" onClick={handleShow} style={{ cursor: 'pointer' }}>Change</span> */}
                                                            </div>
                                                        </section>
                                                        :
                                                        orderCourier ?
                                                            <section className="mt-2">
                                                                <div className="fw-bold">Selected Courier</div>
                                                                <div className="text-muted">{orderCourier.name}</div>
                                                                <div className="fw-bold">Service</div>
                                                                <div className="text-muted d-flex justify-content-between">
                                                                    {
                                                                        <span>{orderCourier.service_display} &nbsp;&nbsp; {global.IDR.format(orderCourier.courier_cost)}</span>
                                                                    }

                                                                    {/* <span className="fw-bold" onClick={() => alert('ssss')} style={{ cursor: 'pointer' }}>Change</span> */}
                                                                </div>
                                                            </section>
                                                            :
                                                            <></>
                                                }
                                            </section>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}><hr /></Col>
                                        <Col md={6}>
                                            <Button color="black" icon="ban" content="Cancel This Order" disabled={btnCclState}
                                                onClick={() => handleCancel(idCustomer)}
                                            />
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>

                        </Col>
                        <Col lg={4} md={4} sm={12}>
                            <SummaryCart
                                cart={cart}
                                totalItem={cart.summary.totalItem}
                                totalPrice={cart.summary.totalPrice}
                                url={null}
                                label={'Select Payment'}
                                setModalPayment={setModalPayment}
                                customerAddress={userAddress}
                                page="checkout"
                                courier={selCompleteCourier}
                            />
                        </Col>
                    </Row>
                </Container>
            ) : (
                <Container fluid='sm'>
                    <Row style={{ height: '65vh' }}>
                        <Col className="d-flex align-items-center justify-content-center">
                            <span className="fs-5">There is nothing here, please come back</span>
                        </Col>
                    </Row>
                </Container>
            )
            }
        </section>
    )
}

export default Checkout;