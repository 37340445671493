import { Col, Container, Row } from "react-bootstrap";
import CarouselBanner from "../../component/CarouselBanner";
import CategoryMain from "../../component/CategoryMain";
import Flashsale from "../../component/Flashsale";
import NewProduct from "../../component/NewProduct";
import { useEffect, useState } from "react";
import axios from "axios"
import moment from "moment";
import { useMyGlobal } from "../../component/GlobalData";
import { Button, Icon, Loader } from "semantic-ui-react";
import { DiscountedProduct } from "../../component/DiscountedProduct";
import { PlayerCategory } from "../../component/PlayerCategory";
import { Toast } from "../../component/Toast";

const HomePage = ({ fsData }) => {
    const [flash, setFlash] = useState([])
    const [newProduct, setNewProduct] = useState([])
    const [prdDsc, setPrdDsc] = useState([])
    const currentTime = moment().format('YYYY-MM-DD HH:mm:ss')
    const [loadNew, setLoadNew] = useState(false)
    const [noNew, setNoNew] = useState(false)
    const { token, windowWidth } = useMyGlobal()
    const [currentPage, setCurrentPage] = useState(0)
    const pagePerLoad = 12
    const [jmlProd, setJmlProd] = useState(pagePerLoad)
    const [loadMoreSpiner, setLoadMoreSpiner] = useState(false)
    const [loadMoreDisable, setLoadMoreDisable] = useState(false)
    const [labelState, setLabelState] = useState('Load More')
    const [hasMore, setHasMore] = useState(true);

    useEffect(() => {
        const handleScroll = () => {
            if (
                window.innerHeight + window.scrollY >= document.documentElement.scrollHeight - 50 && !loadMoreSpiner && hasMore
            ) {
                handleClickLoadMore();
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [loadMoreSpiner, hasMore]);

    const getNewProducts = async (halaman, jumlah) => {
        setLoadMoreSpiner(true)
        setLoadMoreDisable(true)
        await axios({
            method: "GET",
            url: `${global.ERP}api_web/controler/product.php?index=21&halaman=${halaman}&jumlah=${jumlah}`,
            // url: `https://hoopsindonesia.co.id/api_hoops/controler/product.php?index=21&halaman=${halaman}&jumlah=${jumlah}`,
            headers: {
                'Content-Type': 'text/plain',
                'Authorization': token
            }
        })
            .then(res => {
                let data = []
                if (res.data.state === 200) {
                    if (res.data.data.length > 0) {
                        data = res.data.data
                        setLoadNew(true)
                        setNewProduct(prevNew => {
                            // Filter data baru yang belum ada di dalam newProduct
                            const filteredData = data.filter(item =>
                                !prevNew.some(prevItem => prevItem.id_product === item.id_product)
                            );
                            // Gabungkan data lama dengan data baru yang sudah difilter
                            return [...prevNew, ...filteredData];
                        });
                        setLoadMoreSpiner(false)
                        setLoadMoreDisable(false)
                        setHasMore(true)
                    } else {
                        setNoNew(true)
                        setHasMore(false)
                        setLoadMoreDisable(true)
                        setLoadMoreSpiner(false)
                        setLabelState(`That's the last line for new product`)
                    }

                }
            })
    }

    const handleClickLoadMore = () => {
        setCurrentPage(currentPage + pagePerLoad)
        getNewProducts(currentPage + pagePerLoad, jmlProd)
    }

    useEffect(() => {
        const getFS = async () => {
            await axios({
                method: "GET",
                url: `${global.SRV}api_hoops/controler/event.php/?index=2`,
                headers: {
                    'Content-Type': 'text/plain',
                    'Authorization': token
                }
            })
                .then(res => {
                    if (res.data.state === 200) {
                        setFlash(res.data.data)
                    }
                })
                .catch(err => {
                    if (err.response) {
                        console.log(err.response.status)
                    }
                })
        }

        const getProductDiscount = async () => {
            await axios({
                method: "GET",
                url: `https://hoopsdevteam.com/api_web/controler/product.php?index=19`,
                // url: `https://hoopsindonesia.co.id/api_hoops/controler/product.php?index=19`,
                headers: {
                    'Content-Type': 'text/plain',
                    'Authorization': token
                }
            })
                .then(res => {
                    if (res.data.state === 200) {
                        setPrdDsc(res.data.data)
                    }
                })
        }
        getFS()
        getNewProducts(currentPage, jmlProd)
        // getProductDiscount()
    }, [token])

    return (
        <section style={{ height: '100%' }}>
            <CarouselBanner  />
            <Container>
                {/* {
                    <Row>
                        <Col md={12}>
                            <DiscountedProduct data={prdDsc} />
                        </Col>
                    </Row>
                } */}
                {
                    <Row className="mb-5">
                        <Col>
                            <PlayerCategory />
                        </Col>
                    </Row>
                }
                {
                    <Row className="mb-5">
                        <Col>
                            <CategoryMain />
                        </Col>
                    </Row>
                }
                {
                    flash.length > 0 &&
                        currentTime >= flash[0].date_start && currentTime <= flash[0].date_end ?
                        <Row className="mb-5">
                            <Col md={12}>
                                <Flashsale dataFs={flash} />
                            </Col>
                        </Row>
                        :
                        <></>
                }
                {
                    loadNew ?
                        <Row>
                            <Col md={12}>
                                <NewProduct data={newProduct} />
                            </Col>
                            <Col md={12} className="d-flex justify-content-center">
                                {/* <Button
                                    fluid = {windowWidth <= 600 ? true : false}
                                    content={labelState}
                                    onClick={() => handleClickLoadMore()}
                                    loading={loadMoreSpiner}
                                    basic
                                    color="orange"
                                    disabled={loadMoreDisable}
                                    icon={labelState == 'Load More' ? 'redo alternate' : 'ban'}
                                /> */}
                                {
                                    hasMore ? 
                                    <div className="pb-2" style={{height: "20px", textAlign: "center" }}>
                                        {
                                            loadMoreSpiner ? 
                                                <Loader active inline size="small" />
                                            :
                                                <Icon name="angle double down" size="big" className="bounce" /> 
                                        }
                                    </div>
                                    :
                                    <div style={{ height: "20px", textAlign: "center" }}>
                                        <p>All product has been loaded</p>
                                    </div>
                                }
                            </Col>
                        </Row>
                        : noNew ?
                            <></>
                            :
                            <Row>
                                <Col>
                                    <Icon name="spinner" loading />
                                </Col>
                            </Row>
                }

            </Container>
        </section>
    )
}

export default HomePage;