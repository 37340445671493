import {
  Accordion,
  Badge,
  Button,
  ButtonGroup,
  Col,
  Container,
  Form,
  Image,
  InputGroup,
  ListGroup,
  Modal,
  Nav,
  Navbar,
  NavDropdown,
  Offcanvas,
  Row,
  Stack,
} from 'react-bootstrap';
import { Dropdown, Label, Button as Btn, Icon, Popup, Grid, List, ListItem, Image as Img, Placeholder, Form as FormSuir, Input as InputSuir } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Fragment, useEffect, useState } from 'react';
import Login from './Login';
import axios from 'axios';
import queryString from 'query-string';
import Signup from './Signup';
import { useNavigate } from 'react-router-dom';
import { useMyGlobal } from './GlobalData';
import { Toast } from './Toast';
import logoHoops from '../assets/logo_hoops.png'

const NavTop = ({ cart, notif, updateNotif, setCart }) => {
  const ses = window.localStorage
  const navigate = useNavigate()
  const [showLoginModal, setShowLoginModal] = useState(false)
  const [showSignupModal, setShowSignupModal] = useState(false)
  const [login, setLogin] = useState(null)
  const modalLoginClose = () => (setShowLoginModal(false))
  const modalLoginShow = () => {
    setShowLoginModal(true)
    setShowCanvas(false)
  }
  const modalSignupClose = () => (setShowSignupModal(false))
  const modalSignupShow = () => {
    setShowSignupModal(true)
    setShowCanvas(false)
  }
  const [parentCat, setParentCat] = useState([])
  // const [categoryNav, setCategoryNav] = useState([])
  const [defaultCategory, setDefaultCategory] = useState(null)
  const [searchVal, setSearchVal] = useState('')
  const [filteredProduct, setFilteredProduct] = useState([])
  const idCustomer = (ses.getItem('loginData')) ? JSON.parse(ses.getItem('loginData')).id : null
  const [clear, setClear] = useState(false)
  const loginData = localStorage.getItem('loginData')
  const { token, categoryDataNav, categoryData, isPortrait, windowWidth } = useMyGlobal()
  const [showCanvas, setShowCanvas] = useState(false);

  // const [notif, setNotif] = useState([])

  const handleLogout = () => {
    ses.removeItem('loginData')
    ses.removeItem('id_cart')
    ses.removeItem('address')
    Toast.fire({
      icon: 'success',
      title: 'Sign out successfully',
      position: 'center'
    })
    setLogin(null)
    navigate('/')
    setShowCanvas(false)
  }

  const getData = async () => {
    const reqBody = queryString.parse('first_name=Hallo&last_name=World&gender=F&dob=2010-12-30&avatar=')

    await axios({
      method: 'POST',
      url: `${global.SRV}api_hoops/controler/customer.php/?index=2`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': token
      },
      data: reqBody
    })
      .then((response) => {

      });
  }

  const handleClickCart = () => {
    if (cart.is_ordered == 0 || !cart.is_ordered) {
      navigate('/cart')
    } else if (cart.is_ordered == 1) {
      alert('There`s pending order')
      navigate('/cart/checkout')
    } else if (cart.is_ordered == 2) {
      alert('There`s pending payment')
      navigate('/cart/checkout/payment')
    } else if (cart.is_ordered == 3) {
      alert('There`s pending snap payment')
      navigate('/cart/checkout/paymentSnap')
    }
  }

  const getCategoryParent = async () => {
    const param = new URLSearchParams()

    param.append('option', 'root')

    await axios({
      method: "POST",
      url: `${global.ERP}api_web/controler/category.php/?index=1`,
      // url: "https://hoopsindonesia.co.id/api_hoops/controler/category.php/?index=1",
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': token
      },
      data: param
    })
      .then((response) => {
        if (response.data.state === 200) {
          setParentCat(response.data.data)
        }
      })
  }

  // const getCatParentChild = async () => {
  //   const param = new URLSearchParams()
  //   param.append('option', 'parentChild')

  //   await axios({
  //     method: "POST",
  //     url: "https://hoopsdevteam.com/api_web/controler/category.php/?index=1",
  //     headers: {
  //       'Content-Type': 'application/x-www-form-urlencoded',
  //       'Authorization': token
  //     },
  //     data: param
  //   })
  //     .then((response) => {
  //       if (response.data.state === 200) {
  //         setCategoryNav(response.data.data.filter(i => i.on_navigation == 1))
  //       }
  //     })
  // }


  const getDefaultCategory = async (sVal) => {
    const param = new URLSearchParams()
    param.append('option', 'defaultCat')
    setShowCanvas(false)

    await axios({
      method: "POST",
      url: `${global.ERP}api_web/controler/category.php/?index=1`,
      // url: "https://hoopsindonesia.co.id/api_hoops/controler/category.php/?index=1",
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': token
      },
      data: param
    })
      .then(response => {
        let data = {}
        if (response.data.state === 200) {
          data = response.data.data
          data.from = 'parent'
          data.selected_child = 0
          data.search_value = sVal
          setDefaultCategory(data)
          navigate(
            `/product_list/${data.id_category}`,
            { state: data }
          )
        }
      })
  }


  const handleClickNotif = (id_notif, id_customer, date) => {
    const param = new URLSearchParams()

    param.append('id_notif', id_notif)
    param.append('id_customer', id_customer)

    navigate('/customer/notificationPage', { state: { id: id_notif, id_customer: id_customer, date_create: date, id_order: cart.summary.order_id } })


    axios({
      method: "POST",
      url: `${global.SRV}api_hoops/controler/notification.php/?index=2&id_customer=${id_customer}`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': token
      },
      data: param
    })
      .then(response => {
        if (response.data.state === 200) {
          updateNotif(id_customer)
        }
      })
  }

  const getProductList = async (id_category, id_customer, option, sVal) => {
    const param = new URLSearchParams()
    if (id_customer === null) { id_customer = false }

    param.append('id_category', id_category)
    param.append('id_customer', id_customer)
    param.append('option', option)

    await axios({
      method: "POST",
      url: `${global.ERP}api_web/controler/product.php?index=1`,
      // url: "https://hoopsindonesia.co.id/api_hoops/controler/product.php?index=1",
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': token
      },
      data: param
    })
      .then(response => {
        let data
        let filtered = []
        if (response.data.state === 200) {
          data = response.data.data
          filtered = data.filter(i => i.product_name.toString().toLowerCase().match(sVal))
          setFilteredProduct(filtered)
        }
      })
  }

  const handleChangeSearch = (e) => {
    setSearchVal(e.target.value)
    if (e.target.value === '') {
      navigate('/')
    }
  }

  const handleChangeSearchMobile = (e) => {
    setSearchVal(e.target.value)
    if (e.target.value === '') {
      navigate('/')
    }
  }

  const handleCloseCanvas = () => { setShowCanvas(false) }
  const handleShowCanvas = () => { setShowCanvas(true) }

  const handleClickProfile = () => {
    setShowCanvas(false)
    navigate('/customer/profile')
  }

  const handleClickSale = () => {
    navigate("/product/discountedProductPage")
    setShowCanvas(false)
  }

  const handleClickBrand = (data) => {
    setShowCanvas(false)
    let par = data
    par.from = 'parent'
    par.selected_child = 0
    console.log(data)
    navigate(`/product_list/${data.id_category}`, { state: par })
  }

  const handleClickChild = (parent, childId) => {
    setShowCanvas(false)
    let data = parent
    data.from = 'child'
    data.selected_child = childId
    navigate(`/product_list/${childId}`, { state: data })
  }

  useEffect(() => {
    setLogin(JSON.parse(ses.getItem('loginData')))
      getCategoryParent() 
      // getCatParentChild()
      // getDefaultCategory(searchVal)
  }, [])

  return (
    <>
      <Modal show={showLoginModal} onHide={modalLoginClose} backdrop='static' centered>
        <Modal.Header closeButton>
          <Modal.Title>Login</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Login
            setShowModal={setShowLoginModal}
            setLogin={setLogin}
            setCart={setCart}
          />

        </Modal.Body>
      </Modal>

      <Modal show={showSignupModal} onHide={modalSignupClose} backdrop='static' centered>
        <Modal.Header closeButton>
          <Modal.Title>Signup</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Signup
            setShowModal={setShowSignupModal}
          />
        </Modal.Body>
      </Modal>
      {
        windowWidth > 1000 && !isPortrait ?
          <Navbar bg='light' expand='lg' className='fixed-top'>
            <Container fluid="xl">
              <Navbar.Brand>
                <Link to={{ pathname: '/' }}>
                  <div

                  >
                    <Image
                      src={logoHoops}
                      className='zoom'
                      height={40}
                    />
                  </div>
                </Link>
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse className='justify-content-start' style={{ marginLeft: '1%' }}>
                <Nav className='me-auto fw-bold' >
                  {
                    categoryDataNav.length > 0 ?
                      categoryDataNav.map((parent, i) => {
                        if (parent.category_name !== 'HOME') {
                          return (
                            (parent.child.length > 0) ?
                              <Fragment key={i}>
                                <NavDropdown title={parent.category_name}>
                                  {
                                    parent.child.map((child, i) =>
                                      <NavDropdown.Item
                                        key={i}
                                        onClick={() => {
                                          handleClickChild(parent, child.id_category_child)
                                        }
                                        }
                                      >{child.category_name}</NavDropdown.Item>
                                    )
                                  }
                                </NavDropdown>
                                {/* <Popup key={'p' + i} trigger={
                                  <div key={'d' + i} className='me-4 zoom' style={{ cursor: 'default' }}>{parent.category_name}</div>
                                }
                                  hoverable
                                  flowing
                                >
                                  <List key={'l' + i}>
                                    {
                                      parent.child.map((child, x) =>
                                        <ListItem key={x} onClick={() => handleClickChild(parent, child.id_category_child)} style={{ cursor: 'pointer' }} className='mb-2'>{child.category_name}</ListItem>
                                      )
                                    }
                                  </List>
                                </Popup> */}
                              </Fragment>
                              : <Fragment>
                                {
                                  parent.category_name === 'SALE' ?
                                    <Nav.Link>
                                      <div className='me-4 zoom' onClick={handleClickSale} style={{ cursor: 'pointer', color: '#FF5733' }}>{parent.category_name}</div>
                                    </Nav.Link>
                                    :
                                    parent.category_name === 'BRAND' ?
                                      <NavDropdown title={parent.category_name} id="basic-nav-dropdown" >
                                        {categoryData.map((item, index) => (
                                          item.child.length > 0 ?
                                            <NavDropdown title={item.category_name} drop='end' className='fw-light'>
                                              {
                                                item.child.map((child, c) => (
                                                  <NavDropdown.Item key={c} onClick={() => handleClickChild(child, child.id_category_child)}>{child.category_name}</NavDropdown.Item>
                                                ))
                                              }
                                            </NavDropdown>
                                            :
                                            <NavDropdown.Item key={index} onClick={() => handleClickBrand(item)}>{item.category_name}</NavDropdown.Item>
                                        ))}
                                      </NavDropdown>
                                      :
                                      <div className='me-4 zoom'>{parent.category_name}</div>
                                }
                              </Fragment>)
                        }
                      })

                      :
                      <>
                        <span className='me-4'><Placeholder style={{ width: '20rem' }}>&nbsp;</Placeholder></span>
                      </>
                  }

                </Nav>

                <Nav className='ms-4 text-end'>
                  <FormSuir onSubmit={(e) => getDefaultCategory(searchVal)}>
                    <Popup trigger={
                      <InputSuir
                        // style={{background: '#d63384!', border:'2px solid white',borderRadius: '5px'}}
                        value={searchVal}
                        onChange={(e) => handleChangeSearch(e)}
                        icon={{ onClick: (e) => getDefaultCategory(searchVal), name: "search", link: true }}
                        placeholder= "Product Name | Article"
                      />}
                      content="Type Name or Article and then press Enter"
                    />
                  </FormSuir>
                </Nav>
                {
                  (login !== null) ? (<>
                    <Nav className="ms-4">
                      <Nav.Item>
                        <Nav.Link className='align-middle'>

                          {/* <Button variant="outline-secondary" className='align-middle' onClick={() => handleClickCart()} >
                          Cart<Label size='tiny' bg="secondary" className="ms-2">{cart ? cart.is_ordered !== 2 ? cart.products.length : 0 : 0}</Label>
                        </Button> */}
                          <Popup trigger={
                            <Btn.Group basic>
                              <Btn icon labelPosition='left' onClick={() => handleClickCart()}>
                                <Icon name='cart' className={cart.products.length > 0 ? 'border-pulse-color' : ''} />
                                {cart ? cart.is_ordered !== 2 ? cart.products.length : 0 : 0}
                              </Btn>
                            </Btn.Group>
                          }
                            flowing
                            hoverable
                          >
                            {
                              cart.products.length > 0 ?
                                cart.products.map((item, i) =>
                                  <Fragment key={i}>
                                    <Stack direction='horizontal' gap={2} className='mb-3'>
                                      <Image src={item.product_image ? item.product_image.image : 'https://via.placeholder.com/500x400.png'} width={60} height={52} rounded />
                                      <Stack direction='vertical' gap={0}>
                                        <small className='fw-bold'>{item.product_name} (x{item.qty})</small>
                                        <small>{global.IDR.format(item.final_price)}</small>
                                        <small>
                                          <span className='me-3'>Size : {item.selected_size}</span>
                                          <span>Color : &nbsp;
                                            {item.selected_color ?
                                              <>
                                                <canvas className='border-start border-top border-bottom border-dark' width={10} height={10} style={{ backgroundColor: item.selected_color.color_main }} key={1} />
                                                <canvas className='border-top border-bottom border-dark' width={10} height={10} style={{ backgroundColor: item.selected_color.color_secondary }} key={2} />
                                                <canvas className='border-end border-top border-bottom border-dark' width={10} height={10} style={{ backgroundColor: item.selected_color.color_accent }} key={3} />
                                              </>
                                              : <></>
                                            }
                                          </span>
                                        </small>
                                      </Stack>
                                    </Stack>
                                  </Fragment>
                                )
                                :
                                <div className='text-muted'>Cart is empty</div>
                            }
                          </Popup>
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Nav className="ms-4">
                      <Nav.Item>
                        <Label color='orange' circular size='tiny' style={{ position: 'absolute', zIndex: 1, marginLeft: '2.7em', marginTop: '-0.8em' }}>{Array.isArray(notif) ? notif.length : 0}</Label>
                        <Dropdown
                          basic
                          className='icon'
                          icon="bell outline"
                          floating
                          labeled
                          style={{
                            borderRadius: 3,
                            border: '1px solid rgb(212,212,212)',
                            paddingLeft: 10, paddingRight: 7,
                            paddingTop: 7,
                            paddingBottom: 7
                          }}
                        >
                          <Dropdown.Menu style={{ height: '60vh', overflowY: 'auto' }}>
                            <Dropdown.Header icon='bell outline' content='Notification' />
                            <Dropdown.Divider />
                            {
                              notif.length > 0 && Array.isArray(notif) ?
                                notif.map((item, i) => (
                                  <section key={i}>
                                    <Dropdown.Item className='p-3 notif' style={{ cursor: 'pointer' }}
                                      onClick={() => handleClickNotif(item.id, item.id_customer, item.date_create)}
                                    >
                                      <div key={i}>
                                        <Stack direction='vertical' gap={1}>
                                          <Stack direction='horizontal' gap={1}>
                                            <Icon name='envelope' />
                                            <div className='fw-bold'>{item.label}</div>
                                          </Stack>
                                          <div style={{ maxWidth: '200px', whiteSpace: 'pre-wrap' }}><p className='text-truncate'>{item.description}</p></div>
                                          <div className='text-muted' style={{ fontSize: 10 }}>{item.date_create}</div>
                                        </Stack>
                                      </div>
                                    </Dropdown.Item>
                                  </section>
                                ))
                                :
                                <Dropdown.Item>No new notification</Dropdown.Item>
                            }
                            {/* <Dropdown.Item
                            label={{ color: 'red', empty: true, circular: true }}
                            text='Important'
                          />
                          <Dropdown.Item
                            label={{ color: 'blue', empty: true, circular: true }}
                            text='Announcement'
                          />
                          <Dropdown.Item
                            label={{ color: 'black', empty: true, circular: true }}
                            text='Discussion'
                          /> */}
                          </Dropdown.Menu>
                        </Dropdown>
                      </Nav.Item>
                    </Nav>
                  </>) : <></>
                }
                <Nav className="ms-2">
                  <Nav.Link>
                    {
                      (login === null) ? (
                        // <ButtonGroup>
                        //   <Button variant='outline-dark' onClick={modalLoginShow}>Login</Button>
                        //   <Button variant='outline-dark' onClick={modalSignupShow}>Sign Up</Button>
                        // </ButtonGroup>
                        <Btn.Group basic >
                          <Btn content='Login' onClick={modalLoginShow} />
                          <Btn content='Sign up' onClick={modalSignupShow} />
                        </Btn.Group>
                      ) : (<></>
                        // <div className=''>
                        //   <Stack direction='horizontal' gap={3} className='text-end'>
                        //     <Btn.Group basic size='medium' fluid>
                        //       <Btn content={login.profile.first_name} className='text-truncate' style={{ maxWidth: '100px' }} onClick={() => navigate('/customer/profile')} />
                        //       <Btn content="Sign Out" onClick={() => handleLogout()} />
                        //     </Btn.Group>
                        //   </Stack>modalLoginShow
                        // </div>
                      )
                    }
                  </Nav.Link>
                </Nav>
                {login !== null &&
                  <Popup
                    trigger={
                      <Image height={40} width={40} roundedCircle src={JSON.parse(localStorage.getItem("loginData")).profile.avatar != '' ?
                        JSON.parse(localStorage.getItem("loginData")).profile.avatar :
                        `https://ui-avatars.com/api/?name=${JSON.parse(localStorage.getItem("loginData")).profile.first_name}+${JSON.parse(localStorage.getItem("loginData")).profile.last_name}`}
                        onClick={() => navigate('/customer/profile')}
                        style={{ cursor: 'pointer' }}
                      />
                      // <Label as='a' image size='medium' style={{ padding: 11.5 }} onClick={() => navigate('/customer/profile')}>
                      //   <Img src={JSON.parse(localStorage.getItem("loginData")).profile.avatar != '' ?
                      //     JSON.parse(localStorage.getItem("loginData")).profile.avatar :
                      //     `https://ui-avatars.com/api/?name=${JSON.parse(localStorage.getItem("loginData")).profile.first_name}+${JSON.parse(localStorage.getItem("loginData")).profile.last_name}`} 
                      //     size='medium' 
                      //     />
                      //   {JSON.parse(localStorage.getItem("loginData")).profile.first_name}
                      // </Label>
                    }
                    flowing
                    hoverable
                  >
                    <List>
                      <ListItem onClick={() => navigate('/customer/profile')} icon="user outline" content="Profile" style={{ cursor: 'pointer' }} className='mb-2' />
                      <ListItem onClick={() => handleLogout()} icon="logout" content="Logout" style={{ cursor: 'pointer' }} className='mb-2' />
                    </List>
                  </Popup>


                }
              </Navbar.Collapse>
            </Container>
          </Navbar >
          :
          <>
            <Row className='bg-light p-3 fixed-top'>
              <Col xs={2} sm={2} className='d-flex align-items-center justify-content-start'>
                <Image src={logoHoops} height="36" width="48" className=' zoom' onClick={() => navigate('/')} />
              </Col>
              <Col xs={6} sm={6} className='d-flex align-items-center justify-content-start'>
                <FormSuir onSubmit={(e) => getDefaultCategory(searchVal)}>
                  <InputSuir
                    value={searchVal}
                    placeholder="Product Name"
                    type='search'
                    onChange={(e) => handleChangeSearchMobile(e)}
                    icon='search'
                  />
                </FormSuir>
              </Col>
              <Col xs={4} sm={4} className='d-flex align-items-center justify-content-end'>
                <Stack direction='horizontal' gap={3}>
                  <div>
                    <div onClick={() => handleClickCart()}>
                      <Icon name='cart' className={cart.products.length > 0 ? 'border-pulse-color' : ''} size='large' />
                      <Label color='orange' circular size='tiny' style={{ position: 'absolute', zIndex: 1, marginLeft: '-1em', top: '0.9em' }}>{cart ? cart.is_ordered !== 2 ? cart.products.length : 0 : 0}</Label>
                    </div>
                  </div>
                  <div>
                    <Label color='orange' circular size='tiny' style={{ position: 'absolute', zIndex: 1, marginLeft: '1.2em', top: '0.9em' }}>{Array.isArray(notif) ? notif.length : 0}</Label>
                    <Dropdown
                      basic
                      className='icon'
                      icon={<Icon name='bell' size='large' />}
                      floating
                      labeled
                    >
                      <Dropdown.Menu style={{ height: '60vh', overflowY: 'auto' }}>
                        <Dropdown.Header icon='bell outline' content='Notification' />
                        <Dropdown.Divider />
                        {
                          notif.length > 0 && Array.isArray(notif) ?
                            notif.map((item, i) => (
                              <section key={i}>
                                <Dropdown.Item className='p-3 notif' style={{ cursor: 'pointer' }}
                                  onClick={() => handleClickNotif(item.id, item.id_customer, item.date_create)}
                                >
                                  <div key={i}>
                                    <Stack direction='vertical' gap={1}>
                                      <Stack direction='horizontal' gap={1}>
                                        <Icon name='envelope' />
                                        <div className='fw-bold'>{item.label}</div>
                                      </Stack>
                                      <div style={{ maxWidth: '200px', whiteSpace: 'pre-wrap' }}><p className='text-truncate'>{item.description}</p></div>
                                      <div className='text-muted' style={{ fontSize: 10 }}>{item.date_create}</div>
                                    </Stack>
                                  </div>
                                </Dropdown.Item>
                              </section>
                            ))
                            :
                            <Dropdown.Item>No new notification</Dropdown.Item>
                        }
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <Icon name='bars' onClick={handleShowCanvas} size='large' />
                </Stack>
              </Col>
            </Row>
            <Offcanvas show={showCanvas} onHide={handleCloseCanvas}>
              <Offcanvas.Header closeButton>
                <Offcanvas.Title>
                  {
                    (login !== null) ?
                      <Stack direction='horizontal' gap={4}>
                        <Image src={
                          JSON.parse(localStorage.getItem("loginData")).profile.avatar != '' ?
                            JSON.parse(localStorage.getItem("loginData")).profile.avatar :
                            `https://ui-avatars.com/api/?name=${JSON.parse(localStorage.getItem("loginData")).profile.first_name}+${JSON.parse(localStorage.getItem("loginData")).profile.last_name}
                  }`} height={55} width={55} roundedCircle className='border p-1' />
                        <Stack direction='vertical' gap={0}>
                          <div style={{ fontSize: '1em' }}>Hello, {JSON.parse(localStorage.getItem("loginData")).profile.first_name}</div>
                          <Btn.Group color='black' size='mini'>
                            <Btn content="Logout" icon="logout" onClick={() => handleLogout()} size="mini" />
                            <Btn content="Profile" icon="user" onClick={() => handleClickProfile()} />
                          </Btn.Group>
                        </Stack>
                      </Stack>
                      :
                      <></>
                  }
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Accordion >
                  {
                    categoryDataNav.length > 0 ?
                      categoryDataNav.map((parent, i) => {
                        return (
                          (parent.category_name !== 'HOME') ?
                            <Accordion.Item eventKey={i}>
                              {
                                parent.category_name == 'SALE' ?
                                  <>
                                    <Accordion.Item style={{ padding: '12px 10px 12px 16px', border: 'none', color: '#FF5733' }} onClick={handleClickSale}>{parent.category_name}</Accordion.Item>
                                  </>
                                  :
                                  parent.category_name == 'BRAND' ?
                                    <>
                                      <Accordion.Header >{parent.category_name}</Accordion.Header>
                                      <Accordion.Body>
                                        <Accordion style={{ borderWidth: 0 }}>
                                          {
                                            categoryData.map((parent, n) => (
                                              parent.child.length > 0 ?
                                                <Accordion.Item eventKey={n}>
                                                  <Accordion.Header action key={n}>{parent.category_name}</Accordion.Header>
                                                  <Accordion.Body>
                                                    <ListGroup variant='flush'>
                                                      {
                                                        parent.child.map((child, c) => (
                                                          <ListGroup.Item key={c} onClick={() => handleClickBrand(child)}>{child.category_name}</ListGroup.Item>
                                                        ))
                                                      }
                                                    </ListGroup>
                                                  </Accordion.Body>
                                                </Accordion.Item>
                                                :
                                                <Accordion.Item style={{ padding: '12px 10px 12px 16px' }}>{parent.category_name}</Accordion.Item>
                                            ))
                                          }
                                        </Accordion>
                                      </Accordion.Body>
                                    </>
                                    :
                                    <>
                                      <Accordion.Header >{parent.category_name}</Accordion.Header>
                                      <Accordion.Body>
                                        <ListGroup variant='flush'>
                                          {
                                            parent.child.length > 0 ?
                                              parent.child.map((child, x) => {
                                                return (
                                                  <ListGroup.Item
                                                    action
                                                    key={x}
                                                    onClick={() => handleClickChild(parent, child.id_category_child)}
                                                  >{child.category_name}</ListGroup.Item>
                                                )
                                              })
                                              :
                                              <></>
                                          }
                                        </ListGroup>
                                      </Accordion.Body>
                                    </>
                              }
                            </Accordion.Item>
                            : <></>
                          // <Accordion.Item eventKey={i}>
                          //   <Accordion.Header>{parent.category_name}</Accordion.Header>
                          //   <Accordion.Body>
                          //     {parent.category_name}
                          //   </Accordion.Body>
                          // </Accordion.Item>
                        )
                      })
                      :
                      <>Loading...</>
                  }
                </Accordion>

                {
                  login === null ?
                    <div className='mt-5'>
                      <Btn.Group fluid>
                        <Btn content='Login' onClick={modalLoginShow} color="black" />
                        <Btn.Or />
                        <Btn content='Sign up' onClick={modalSignupShow} color="grey" />
                      </Btn.Group>
                    </div>

                    : <></>
                }
                <div className='d-flex justify-content-center' style={{ position: 'absolute', bottom: '20px', width: '100%' }}>
                  <p>@2024. <span style={{ color: '#FF5733' }}>JFS Developer.</span></p>
                </div>
              </Offcanvas.Body>
            </Offcanvas>
          </>
      }
    </>
  )
}

export default NavTop;