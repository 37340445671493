import React, { Fragment, useEffect, useState } from "react";
import { Col, Container, Image, Row, Stack, Tab, Table } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { Divider } from "semantic-ui-react";
import { useMyGlobal } from "../../component/GlobalData";


const NotificationPage = () => {
    const notifData = useLocation().state
    const date = new Date(notifData.date_create)
    const dateFormated = date.toLocaleDateString('en-IN', { weekday: "long", year: "numeric", month: "short", day: "numeric", hour: "numeric", minute: "numeric", second: "numeric" })
    const [item, setItem] = useState([])
    const [notif, setNotif] = useState(null)
    const {token} = useMyGlobal()

    const getOrder = (idOrder) => {
        const param = new URLSearchParams()
        param.append('id_order', idOrder)
        param.append('id_customer', notifData.id_customer)

        axios({
            method: "POST",
            url: `${global.SRV}api_hoops/controler/order.php/?index=12`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization' : token
            },
            data: param
        })
            .then(res => {
                if (res.data.state === 200) {
                    setItem(res.data.data.detail)
                }
            })
    }

    const getNotif = (id, idCus) => {
        const param = new URLSearchParams()
        const id_customer = idCus
        param.append('id', id)

        axios({
            method: "POST",
            url: `${global.SRV}api_hoops/controler/notification.php/?index=3&id_customer=${id_customer}`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization' : token
            },
            data: param
        })
            .then(res => {
                if (res.data.state === 200) {
                    setNotif(res.data.data[0])
                    getOrder(res.data.data[0].id_order)
                }
            })
    }

    const NotifMsg = ({notif}) => {
        if (notif.group === 'order') {
            return (
                <Row className="d-flex justify-content-center mb-3">
                    <Col xs={12} sm={12} md={10} lg={8} xl={6}>
                        <h3 className="text-capitalize">{notif.label}</h3>
                        <p>{notif.description}</p>
                        <p>At {dateFormated}</p>
                    </Col>
                </Row>
            )
        }
        
        if (notif.group === 'payment') {
            return (
                <Row className="d-flex justify-content-center mb-3">
                    <Col xs={12} sm={12} md={10} lg={8} xl={6}>
                        <h3 className="text-capitalize">{notif.label}</h3>
                        <p>{notif.description}</p>
                        <p>At {dateFormated}</p>
                    </Col>
                </Row>
            )
        }

        return false
    }


    useEffect(() => {
        getNotif(notifData.id, notifData.id_customer)
    }, [notifData])

  
    return (
        <>
            <section style={{ marginTop: "10rem", minHeight: "100vh", height: "100%", textAlign: 'left' }}>
                <Container>
                    {notif ? <NotifMsg notif={notif}/> : <>Loading ...</>}
                    <Row className="d-flex justify-content-center mb-3">
                        <Col xs={12} sm={12} md={10} lg={8} xl={6}>
                            <Divider />
                            {
                                item &&
                                item.map((itm, i) => (
                                    <section key={i} className="mb-3">
                                        <Stack direction="horizontal" gap={2}>
                                            <Image src={itm.image} height={64} width={70} rounded />
                                            <Stack direction="vertical" gap={1}>
                                                <div>{itm.item_name}</div>
                                                <div>{global.IDR.format(itm.total_price)}</div>
                                                <div>
                                                    <span className="me-3">Size : {itm.size}</span>
                                                    <span className="me-3">Qty : {itm.qty}</span>
                                                </div>
                                            </Stack>
                                        </Stack>
                                    </section>
                                ))
                            }
                            <p className="text-muted mt-5"><i>This notification will disappear after opening it.</i></p>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default NotificationPage