import React, { useEffect } from "react";
import { Col, Container, Row, Stack } from "react-bootstrap";
import { Icon, Label, List } from "semantic-ui-react";

const ContactPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    })
    return (
        <>
            <section style={{ marginTop: "10rem", minHeight: "100vh", height: "100%" }}>
                <Container>
                    <Row className="d-flex justify-content-center mb-4">
                        <Col md={12}>
                            <div className="h2">Contact Information</div>
                            <div className="fs-5 text-muted" style={{ fontWeight: 'lighter' }}>
                                This is an information page about our location
                            </div>
                        </Col>
                    </Row>
                    <Row className="d-flex justify-content-center mb-3">
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <div className="contact-container">
                                <div className="contact-info">
                                    <Stack direction="horizontal" gap={2} className="fs-3 mt-5 ms-5 pe-5">
                                        <div style={{ minWidth: '40px' }}><Icon circular name="map marker alternate" /></div>
                                        <Stack direction="vertical" gap={1}>
                                            <div className="ms-3 mb-2 fs-4">Address</div>
                                            <div className="ms-3 fs-5">Jl. Surapati No.61 Sadang Serang, Kecamatan Coblong.</div>
                                            <div className="ms-3 fs-5">Kota Bandung, Jawa Barat 40123</div>
                                        </Stack>
                                    </Stack>
                                    {/* <Stack direction="horizontal" gap={2} className="fs-3 mt-5 ms-5 pe-5">
                                        <div style={{minWidth:'40px'}}><Icon circular name="phone" /></div>
                                        <Stack direction="vertical" gap={1}>
                                            <div className="ms-3 mb-2 fs-4">Phone</div>
                                            <div className="ms-3 fs-5">022 - 2506999</div>
                                        </Stack>
                                    </Stack> */}
                                    <Stack direction="horizontal" gap={2} className="fs-3 mt-5 ms-5 pe-5">
                                        <div style={{ minWidth: '40px' }}><Icon circular name="whatsapp" /></div>
                                        <Stack direction="vertical" gap={1}>
                                            <div className="ms-3 mb-2 fs-4">Whatsapp</div>
                                            <div className="ms-3 fs-5"><a href="https://wa.me/0811220187">0858-4622-2115</a></div>
                                        </Stack>
                                    </Stack>
                                    <Stack direction="horizontal" gap={2} className="fs-3 mt-5 ms-5 pe-5">
                                        <div style={{ minWidth: '40px' }}><Icon circular name="mail" /></div>
                                        <Stack direction="vertical" gap={1}>
                                            <div className="ms-3 mb-2 fs-4">Email</div>
                                            <div className="ms-3 fs-5">hoops@hoopsdevteam.com</div>
                                        </Stack>
                                    </Stack>
                                </div>
                                <div className="contact-map">
                                    <iframe
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d17912.883729375728!2d107.61008346918796!3d-6.897958982155853!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e68e64d2e712903%3A0x84659450a2d10b9d!2sHoops%20Indonesia%20Surapati!5e0!3m2!1sid!2sid!4v1696221570597!5m2!1sid!2sid"
                                        width="100%" height="100%"
                                        className="contact-map-map"
                                    />
                                </div>
                            </div>
                        </Col>
                        <Col className="mt-5" xl={12} lg={12} md={12} sm={12}>
                            <Row className="d-flex justify-content-start">
                                <Col xs={12} sm={12} md={6} lg={4} xl={4} className="mb-4">
                                    <div className="contact-container-child">
                                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d106720.59787426075!2d106.76112971956006!3d-6.260049012967753!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f1aa01c2db73%3A0xbe5af127eac26728!2sHOOPS%20Indonesia!5e0!3m2!1sid!2sid!4v1710311650521!5m2!1sid!2sid"
                                            width="100%" height="100%"
                                            allowfullscreen=""
                                            loading="lazy"
                                            referrerpolicy="no-referrer-when-downgrade"
                                            className="contact-map-map-child mb-2"
                                        />
                                        <div className="contact-info-child">
                                            <div>Hoops Indonesia (Senayan City JKT)</div>
                                            <div>Phone : 0812-1981-8713</div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} sm={12} md={6} lg={4} xl={4} className="mb-4">
                                    <div className="contact-container-child">
                                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.5185934581314!2d106.81802247475034!3d-6.19509629379255!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f5d9953daefb%3A0x447ec9223c2308f5!2sHoops!5e0!3m2!1sid!2sid!4v1710312074606!5m2!1sid!2sid"
                                            width="100%" height="100%"
                                            allowfullscreen=""
                                            loading="lazy"
                                            referrerpolicy="no-referrer-when-downgrade"
                                            className="contact-map-map-child mb-2"
                                        />
                                        <div className="contact-info-child">
                                            <div>Hoops Indonesia (Grand Indonesia JKT)</div>
                                            <div>Phone : 0852-9533-8165</div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} sm={12} md={6} lg={4} xl={4} className="mb-4">
                                    <div className="contact-container-child">
                                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.802753974977!2d106.90323857731973!3d-6.157166477921012!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f5f3d51f891f%3A0x80fa80e67de481a3!2sHoops%20Indonesia!5e0!3m2!1sid!2sid!4v1710312214782!5m2!1sid!2sid"
                                            width="100%" height="100%"
                                            allowfullscreen=""
                                            loading="lazy"
                                            referrerpolicy="no-referrer-when-downgrade"
                                            className="contact-map-map-child mb-2"
                                        />
                                        <div className="contact-info-child">
                                            <div>Hoops Indonesia (MKG JKT)</div>
                                            <div>Phone : 0812-9261-0462</div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} sm={12} md={6} lg={4} xl={4} className="mb-4">
                                    <div className="contact-container-child">
                                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.677674673257!2d106.78702247475007!3d-6.173890693813482!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f71a9d4de82b%3A0x98899137b4da0f76!2sHoops%20Central%20Park!5e0!3m2!1sid!2sid!4v1710382339949!5m2!1sid!2sid"
                                            width="100%" height="100%"
                                            allowfullscreen=""
                                            loading="lazy"
                                            referrerpolicy="no-referrer-when-downgrade"
                                            className="contact-map-map-child mb-2"
                                        />
                                        <div className="contact-info-child">
                                            <div>Hoops Indonesia (Central Park JKT)</div>
                                            <div>Phone : 0812-8516-2484</div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} sm={12} md={6} lg={4} xl={4} className="mb-4">
                                    <div className="contact-container-child">
                                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d63466.17571046646!2d106.77736051774461!3d-6.179455368687346!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f6425748ef2b%3A0x9431adffaa318ea7!2sHoops%20Indonesia%20Grogol!5e0!3m2!1sid!2sid!4v1711431291586!5m2!1sid!2sid"
                                            width="100%" height="100%"
                                            allowfullscreen=""
                                            loading="lazy"
                                            referrerpolicy="no-referrer-when-downgrade"
                                            className="contact-map-map-child mb-2"
                                        />
                                        <div className="contact-info-child">
                                            <div>Hoops Indonesia (Grogol JKT)</div>
                                            <div>Phone : 0812-9713-5252</div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} sm={12} md={6} lg={4} xl={4} className="mb-4">
                                    <div className="contact-container-child">
                                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d126920.9148890829!2d106.46698236465458!3d-6.226952701831223!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69fc1f9ad68e75%3A0x50b1748c82e5b672!2sHoops%20Indonesia%20Karawaci!5e0!3m2!1sid!2sid!4v1710383304270!5m2!1sid!2sid"
                                            width="100%" height="100%"
                                            allowfullscreen=""
                                            loading="lazy"
                                            referrerpolicy="no-referrer-when-downgrade"
                                            className="contact-map-map-child mb-2"
                                        />
                                        <div className="contact-info-child">
                                            <div>Hoops Indonesia (Karawaci TRG)</div>
                                            <div>Phone : 0857-8174-9708</div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} sm={12} md={6} lg={4} xl={4} className="mb-4">
                                    <div className="contact-container-child">
                                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.1753642860135!2d106.62490119440935!3d-6.240604410911625!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69fd2fc76f684d%3A0xe36aa113aacc648a!2sHoops%20Indonesia%20Sumarecon%20Mall%20Serpong!5e0!3m2!1sid!2sid!4v1711431609551!5m2!1sid!2sid"
                                            width="100%" height="100%"
                                            allowfullscreen=""
                                            loading="lazy"
                                            referrerpolicy="no-referrer-when-downgrade"
                                            className="contact-map-map-child mb-2"
                                        />
                                        <div className="contact-info-child">
                                            <div>Hoops Indonesia (Summarecon Serpong TRG)</div>
                                            <div>Phone : 0811-9731-661</div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} sm={12} md={6} lg={4} xl={4} className="mb-4">
                                    <div className="contact-container-child">
                                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d849095.0197691083!2d114.97948108353!3d-8.659625631039823!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2dd2470d257a7ef5%3A0xd252add1304b3a3c!2sHoops%20Indonesia%20Mall%20Bali%20Galeria!5e0!3m2!1sid!2sid!4v1711431774706!5m2!1sid!2sid"
                                            width="100%" height="100%"
                                            allowfullscreen=""
                                            loading="lazy"
                                            referrerpolicy="no-referrer-when-downgrade"
                                            className="contact-map-map-child mb-2"
                                        />
                                        <div className="contact-info-child">
                                            <div>Hoops Indonesia (Mall Galeria Bali)</div>
                                            <div>Phone : 0812-6275-9388</div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} sm={12} md={6} lg={4} xl={4} className="mb-4">
                                    <div className="contact-container-child">
                                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2023188.195437087!2d114.48399250021984!3d-7.943071926507864!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2dd23fb111e0945b%3A0x9e3f25a30c48fbad!2sHoops%20Indonesia%20Living%20World%20Bali!5e0!3m2!1sid!2sid!4v1710383666698!5m2!1sid!2sid"
                                            width="100%" height="100%"
                                            allowfullscreen=""
                                            loading="lazy"
                                            referrerpolicy="no-referrer-when-downgrade"
                                            className="contact-map-map-child mb-2"
                                        />
                                        <div className="contact-info-child">
                                            <div>Hoops Indonesia (living World Bali)</div>
                                            <div>Phone : 0813-9261-2588</div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} sm={12} md={6} lg={4} xl={4} className="mb-4">
                                    <div className="contact-container-child">
                                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d253284.9525721379!2d112.50903058905446!3d-7.288829340887798!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2dd7fd773bc490cf%3A0x3f78bed616093911!2sHoops%20Indonesia%20Pakuwon%20Mall!5e0!3m2!1sid!2sid!4v1711432118061!5m2!1sid!2sid"
                                            width="100%" height="100%"
                                            allowfullscreen=""
                                            loading="lazy"
                                            referrerpolicy="no-referrer-when-downgrade"
                                            className="contact-map-map-child mb-2"
                                        />
                                        <div className="contact-info-child">
                                            <div>Hoops Indonesia (Pakuwon Mall SBY)</div>
                                            <div>Phone : 0822-5746-3995</div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} sm={12} md={6} lg={4} xl={4} className="mb-4">
                                    <div className="contact-container-child">
                                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d253284.9525721379!2d112.50903058905446!3d-7.288829340887798!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2dd7fa3b5b94c4a1%3A0x8910ee4776002c89!2sHoops%20Indonesia%20Galaxy%20Mall!5e0!3m2!1sid!2sid!4v1711432170871!5m2!1sid!2sid"
                                            width="100%" height="100%"
                                            allowfullscreen=""
                                            loading="lazy"
                                            referrerpolicy="no-referrer-when-downgrade"
                                            className="contact-map-map-child mb-2"
                                        />
                                        <div className="contact-info-child">
                                            <div>Hoops Indonesia (Galaxy Mall SBY)</div>
                                            <div>Phone : 0811-3443-843</div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} sm={12} md={6} lg={4} xl={4} className="mb-4">
                                <div className="contact-container-child">
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d253284.9525721379!2d112.50903058905446!3d-7.288829340887798!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2dd7f9b0c3e0ca57%3A0xfa2c2b9437885e69!2sHoops%20-%20Tunjungan%20Plaza%201!5e0!3m2!1sid!2sid!4v1711432231223!5m2!1sid!2sid"
                                        width="100%" height="100%"
                                        allowfullscreen=""
                                        loading="lazy"
                                        referrerpolicy="no-referrer-when-downgrade"
                                        className="contact-map-map-child mb-2"
                                    />
                                    <div className="contact-info-child">
                                        <div>Hoops Indonesia (Tunjungan Plaza SBY)</div>
                                        <div>Phone : 0813-3080-4824</div>
                                    </div>
                                </div>
                                </Col>
                                <Col xs={12} sm={12} md={6} lg={4} xl={4} className="mb-4">
                                    <div className="contact-container-child">
                                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d506981.9448405797!2d107.036190032959!3d-6.914924128924453!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e68e73072074f99%3A0x70e735a6352fcaf3!2sHoops%20Indonesia%20Paskal%2023!5e0!3m2!1sid!2sid!4v1710383522234!5m2!1sid!2sid"
                                            width="100%" height="100%"
                                            allowfullscreen=""
                                            loading="lazy"
                                            referrerpolicy="no-referrer-when-downgrade"
                                            className="contact-map-map-child mb-2"
                                        />
                                        <div className="contact-info-child">
                                            <div>Hoops Indonesia (Paskal 23 BDG)</div>
                                            <div>Phone : 0858-7106-7757</div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} sm={12} md={6} lg={4} xl={4} className="mb-4">
                                    <div className="contact-container-child">
                                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3944.0514156157333!2d115.2627933!3d-8.6866608!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2dd24100440e5701%3A0x45ca33538c2494c4!2sHoops%20Icon%20Bali!5e0!3m2!1sid!2sid!4v1740468528162!5m2!1sid!2sid"
                                            width="100%" height="100%"
                                            allowfullscreen=""
                                            loading="lazy"
                                            referrerpolicy="no-referrer-when-downgrade"
                                            className="contact-map-map-child mb-2"
                                        />
                                        <div className="contact-info-child">
                                            <div>Hoops Indonesia (Icon Bali)</div>
                                            <div>Phone : 0812-1430-6217</div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} sm={12} md={6} lg={4} xl={4} className="mb-4">
                                    <div className="contact-container-child">
                                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3953.2893495392636!2d110.3995333!3d-7.7591062!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e7a59d5af9dc0b3%3A0x837cd31cbe55b26e!2sHoops%20Indonesia%20Pakuwon%20Mall%20Jogja!5e0!3m2!1sid!2sid!4v1740468595413!5m2!1sid!2sid"
                                            width="100%" height="100%"
                                            allowfullscreen=""
                                            loading="lazy"
                                            referrerpolicy="no-referrer-when-downgrade"
                                            className="contact-map-map-child mb-2"
                                        />
                                        <div className="contact-info-child">
                                            <div>Hoops Indonesia (Yogya Pakuwon)</div>
                                            <div>Phone : 0811-2630-855</div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} sm={12} md={6} lg={4} xl={4} className="mb-4">
                                    <div className="contact-container-child">
                                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3967.1623162464334!2d106.74021529999999!3d-6.1088358!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e6a1d3dd159b039%3A0x8be28ca3ab03de89!2sHoops%20Indonesia%20PIK%20Avenue!5e0!3m2!1sid!2sid!4v1740468664275!5m2!1sid!2sid"
                                            width="100%" height="100%"
                                            allowfullscreen=""
                                            loading="lazy"
                                            referrerpolicy="no-referrer-when-downgrade"
                                            className="contact-map-map-child mb-2"
                                        />
                                        <div className="contact-info-child">
                                            <div>Hoops Indonesia (Pantai Indah Kapuk)</div>
                                            <div>Phone : 0811-1001-0652</div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} sm={12} md={6} lg={4} xl={4} className="mb-4">
                                    <div className="contact-container-child">
                                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3965.5383257921344!2d107.33979199999999!3d-6.3242037!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e6977f457f978f1%3A0x39feafde3f067e37!2sHoops%20Indonesia%20Summarecon%20Villagio%20Outlets!5e0!3m2!1sid!2sid!4v1740468718300!5m2!1sid!2sid"
                                            width="100%" height="100%"
                                            allowfullscreen=""
                                            loading="lazy"
                                            referrerpolicy="no-referrer-when-downgrade"
                                            className="contact-map-map-child mb-2"
                                        />
                                        <div className="contact-info-child">
                                            <div>Hoops Indonesia (Villagio Karawang)</div>
                                            <div>Phone : 0852-8153-5452</div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} sm={12} md={6} lg={4} xl={4} className="mb-4">
                                    <div className="contact-container-child">
                                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3984.425665235657!2d104.74510199999999!3d-2.9792799999999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e3b75e6d62d790f%3A0x4f51deef4db78c49!2sPalembang%20Icon%20Mall!5e0!3m2!1sid!2sid!4v1740469017530!5m2!1sid!2sid"
                                            width="100%" height="100%"
                                            allowfullscreen=""
                                            loading="lazy"
                                            referrerpolicy="no-referrer-when-downgrade"
                                            className="contact-map-map-child mb-2"
                                        />
                                        <div className="contact-info-child">
                                            <div>Hoops Indonesia (Palembang)</div>
                                            <div>Phone : 0813-7659-4127</div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} sm={12} md={6} lg={4} xl={4} className="mb-4">
                                    <div className="contact-container-child">
                                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3973.654081848548!2d119.39427160000001!3d-5.159236400000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2dbf1d9afdecdd19%3A0x902a3908c837d182!2sTrans%20Studio%20Mall%20Makassar!5e0!3m2!1sid!2sid!4v1740469088305!5m2!1sid!2sid"
                                            width="100%" height="100%"
                                            allowfullscreen=""
                                            loading="lazy"
                                            referrerpolicy="no-referrer-when-downgrade"
                                            className="contact-map-map-child mb-2"
                                        />
                                        <div className="contact-info-child">
                                            <div>Hoops Indonesia (Makassar)</div>
                                            <div>Phone : 0812-3585-9866</div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} sm={12} md={6} lg={4} xl={4} className="mb-4">
                                    <div className="contact-container-child">
                                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3981.969090819878!2d98.67440739999999!3d3.5945605999999994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x303131c416b88825%3A0xc035ba4fa455d423!2sDeliPark%20Mall!5e0!3m2!1sid!2sid!4v1740469290732!5m2!1sid!2sid"
                                            width="100%" height="100%"
                                            allowfullscreen=""
                                            loading="lazy"
                                            referrerpolicy="no-referrer-when-downgrade"
                                            className="contact-map-map-child mb-2"
                                        />
                                        <div className="contact-info-child">
                                            <div>Hoops Indonesia (Delipark Medan)</div>
                                            <div>Phone : 0821-8561-9328</div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} sm={12} md={6} lg={4} xl={4} className="mb-4">
                                    <div className="contact-container-child">
                                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3982.0245526115864!2d98.66881847310154!3d3.581834550344416!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30313161856c36bf%3A0xd95af98909df38ae!2sHoops%20Indonesia%20Sun%20Plaza%20Medan!5e0!3m2!1sid!2sid!4v1740469365278!5m2!1sid!2sid"
                                            width="100%" height="100%"
                                            allowfullscreen=""
                                            loading="lazy"
                                            referrerpolicy="no-referrer-when-downgrade"
                                            className="contact-map-map-child mb-2"
                                        />
                                        <div className="contact-info-child">
                                            <div>Hoops Indonesia (Sun Plaza Medan)</div>
                                            <div>Phone : 0813-6239-2282</div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section >
        </>
    )
}

export default ContactPage