import axios from "axios";
import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { Wheel } from "react-custom-roulette";
import { Button, Label } from "semantic-ui-react";
import { useMyGlobal } from './GlobalData';
import { Toast } from "./Toast";


const Roulette = ({ data, cart, setCart, setSpin, roulette }) => {
    const [mustSpin, setMustSpin] = useState(false);
    const [prizeNumber, setPrizeNumber] = useState(0);
    const [rouletteData, setRouletteData] = useState(data);
    const [played, setPlayed] = useState(false)
    const { token } = useMyGlobal()
    const [currentPrize, setCurrentPrize] = useState(null)

   
    const handleSpinClick = () => {
        const newPrizeNumber = Math.floor(Math.random() * data.length);
        const id_customer = cart?.customer?.id_customer
        let prize = { ...data[newPrizeNumber], id_customer: id_customer };
       
        setPlayed(true)
        updateChance(cart)
        if (newPrizeNumber == 1) {
            setPrizeNumber(data.length -1)
        } else {
            setPrizeNumber(newPrizeNumber)
        }
        
        keepPrize(prize)
        setMustSpin(true);
    };
  
    const keepPrize = (prize) => {
        axios({
            method: "POST",
            url: `${global.SRV}api_hoops/controler/event.php/?index=11`,
            headers: {
                'Content-Type': 'text/plain',
                'Authorization': token

            },
            data: prize
        }).then (res => {
            if (res.data.state === 200){
                console.log('prize keep')
            }
        })
    }

    const cartProduct = async (idCustomer) => {
        const param = new URLSearchParams()
        param.append('id_customer', idCustomer)

        await axios({
            method: "POST",
            url: `${global.SRV}api_hoops/controler/cart.php/?index=5`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': token

            },
            data: param
        })
            .then((response) => {
                if (response.data.state == 200) {
                    let data = response.data.data
                    setCart(data)
                }
            })
            .catch((error) => console.log(error.response.state))
    }


    const getCurrentPrize = async (idCustomer) => {
        await axios({
            method: "GET",
            url: `${global.SRV}api_hoops/controler/event.php/?index=12`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': token

            },
            params:{
                id_customer:idCustomer
            }
        }).then(res => {
            if (res.data.state === 200){
                setCurrentPrize(res.data.data)
            }
        })
    }

    const handleClickTakePrize = () => { 
        const prize = data[prizeNumber]
        if (prize.prize_tipe === 'zoonk') {
            Toast.fire({
                icon:'warning',
                text:'You are not lucky, try next time...',
                position:'top-right'
            })
        } else {
            let msg = window.confirm("Are you sure you will take this prize?")
            if (msg) { 
                if (prizeNumber > 0){ 
                    if (prize.prize_tipe === 'voucher') {
                        const param = data[prizeNumber]
                        param.id_customer = cart.customer.id_customer
                        param.id_member = cart.customer.id_member
                        param.end_date = roulette[0].end_date

                        axios({
                            method: "POST",
                            url: `${global.SRV}api_hoops/controler/voucher.php/?index=5`,
                            headers: {
                                'Content-Type': 'text/plain',
                                'Authorization': token
                            },
                            data: param
                        })
                            .then(res => {
                                if (res.data.state === 200) {
                                    Toast.fire({
                                        icon:'success',
                                        text:'The prize has been successfully add in the voucher list and is ready to be used',
                                        position:'top-right'
                                    })
                                    updateClaim()
                                } else {
                                    Toast.fire({
                                        icon:'error',
                                        text : 'Failed to prepare Prize',
                                        position:'top-right'
                                    })
                                }
                            })
                    } else if (prize.prize_tipe === 'item') {
                        const param = new URLSearchParams()
                        const prize = data[prizeNumber]
                        param.append('id_cart', cart.summary.id_cart)
                        param.append('id_product', prize.id_product)
                        param.append('barcode', prize.barcode)
                        param.append('size', prize.size)

                        axios({
                            method: "POST",
                            url: `${global.SRV}api_hoops/controler/cart.php/?index=13`,
                            headers: {
                                'Content-Type': 'application/x-www-form-urlencoded',
                                'Authorization': token
                            },
                            data: param
                        })
                            .then(res => {
                                if (res.data.state === 200) {
                                    cartProduct(cart.customer.id_customer)
                                    updateClaim()
                                }
                            })
                    } 
                }else{
                    if (currentPrize){
                        if (prize.prize_tipe === 'voucher') {
                            const param = currentPrize
                            param.id_customer = cart.customer.id_customer
                            param.id_member = cart.customer.id_member
                            param.end_date = roulette[0].end_date

                            axios({
                                method: "POST",
                                url: `${global.SRV}api_hoops/controler/voucher.php/?index=5`,
                                headers: {
                                    'Content-Type': 'text/plain',
                                    'Authorization': token
                                },
                                data: param
                            })
                                .then(res => {
                                    if (res.data.state === 200) {
                                        Toast.fire({
                                            icon:'success',
                                            text:'The prize has been successfully add in the voucher list and is ready to be used',
                                            position:'top-right'
                                        })
                                        updateClaim()
                                    } else {
                                        Toast.fire({
                                            icon:'error',
                                            text : 'Failed to prepare Prize',
                                            position:'top-right'
                                        })
                                    }
                                })
                        } else if (prize.prize_tipe === 'item') {
                            const param = new URLSearchParams()
                            const prize = currentPrize
                            param.append('id_cart', cart.summary.id_cart)
                            param.append('id_product', prize.id_product)
                            param.append('barcode', prize.barcode)
                            param.append('size', prize.size)

                            axios({
                                method: "POST",
                                url: `${global.SRV}api_hoops/controler/cart.php/?index=13`,
                                headers: {
                                    'Content-Type': 'application/x-www-form-urlencoded',
                                    'Authorization': token
                                },
                                data: param
                            })
                                .then(res => {
                                    if (res.data.state === 200) {
                                        cartProduct(cart.customer.id_customer)
                                        updateClaim()
                                    }
                                })
                        } 
                    } else {
                        Toast.fire({
                            icon:'warning',
                            text:"Sorry you haven't gotten any prizes",
                            position:'top-right'
                        })
                    }
                }
            }
        }
    }

    const updateClaim = () => { 
        const param = new FormData()
        param.append('id_cart', cart.summary.id_cart)
        param.append('id_customer', cart.customer.id_customer)

        axios({
            method: "POST",
            url: `${global.SRV}api_hoops/controler/cart.php/?index=16`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': token
            },
            data: param
        }). then (res => {
            if (res.data.state === 200){
                console.log('claim updated')
                setSpin(false)
            }
        })
    }

    const updateChance = (data) => {
        const param = new URLSearchParams()
        param.append('id_cart', data.summary.id_cart)
        param.append('id_customer', data.customer.id_customer)
        param.append('option', '-1')
        param.append('is_played', cart.is_played)
        
        axios({
            method: "POST",
            url: `${global.SRV}api_hoops/controler/cart.php/?index=14`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': token
            },
            data: param
        })
            .then(res => {
                console.log(res.data)
            })
    }

    useEffect(() => {
        const addShortString = data.map((item) => { 
            return {
                completeOption: item.name,
                option:
                    item.name.length >= 15
                        ? item.name.substring(0, 15).trimEnd() + "..."
                        : item.name,
                // image: {
                //     uri:"https://media.wired.com/photos/593261cab8eb31692072f129/master/w_1920,c_limit/85120553.jpg",
                //     landscape:true
                // }
            }
        })
        setRouletteData(addShortString);
        
    }, [data])

    useEffect(() => {
        getCurrentPrize(cart.customer.id_customer)
    }, [cart])
    
    useEffect(() => {
        setPrizeNumber(0)
        setMustSpin(false)
        cartProduct(cart.customer.id_customer) 
    }, [])

    
    return (
        <>
            <Row className="mt-3 mb-5">
                <Col md={12} className="text-center mb-3">
                    {
                        played || mustSpin ?
                        <div className="fw-bold mb-3">Congratulations on getting the following prize</div>
                        : <></>
                    }
                    <Label
                        className={!mustSpin ? 'zoomin-button mb-2' : "mb-2"}
                        color="orange"
                        circular
                        basic
                        size="big"
                        // onClick={handleSpinClick}
                        content={played ? !mustSpin ? rouletteData[prizeNumber]?.completeOption : "Wait..." : "No prizes yet..."}
                    />
                    <div>Check the voucher list to see the voucher prizes <br/></div>
                </Col>
                <Col md={12} className='d-flex justify-content-center mb-3'>
                    <div >
                        {
                            typeof prizeNumber == 'number' ?
                        <Wheel
                            mustStartSpinning={mustSpin}
                            spinDuration={[0.6]}
                            prizeNumber={prizeNumber == data.length ? prizeNumber -1 : prizeNumber}
                            data={data?.map(item => ({
                                option:item.name.length >= 15
                                ? item.name.substring(0, 15).trimEnd() + "..."
                                : item.name,
                                // image:{
                                //     uri:item.image,
                                //     offsetX:-100,
                                //     offsetY:40,
                                //     sizeMultiplier: 1,
                                //     landscape: true
                                // } 
                                
                            }))}
                            outerBorderColor={["#b2b2"]}
                            outerBorderWidth={[5]}
                            innerBorderColor={["#f2f2f2"]}
                            radiusLineColor={["tranparent"]}
                            radiusLineWidth={[1]}
                            textColors={["#f5f5f5"]}
                            textDistance={55}
                            fontSize={[14]}
                            backgroundColors={[
                                "#3f297e",
                                "#175fa9",
                                "#169ed8",
                                "#239b63",
                                "#64b031",
                                "#efe61f",
                                "#f7a416",
                                "#e6471d",
                                "#dc0936",
                                "#e5177b",
                                "#be1180",
                                "#871f7f"
                            ]}
                            onStopSpinning={() => {
                                setMustSpin(false);
                                cartProduct(cart.customer.id_customer)
                            }}
                            // renderSegmentContent={(segment) => (
                            //     <img src='https://media.wired.com/photos/593261cab8eb31692072f129/master/w_1920,c_limit/85120553.jpg' alt="segment" style={{ width: '50px', height: '50px' }} />
                            //   )}
                        />
                        :<>error</>
                        }
                    </div>
                </Col>
                <Col md={12} className="text-center">
                    <div className="mb-2 fw-bold">Chance : {cart.roulette_chance}</div>
                    {
                        !played && !mustSpin ?
                                cart.is_played === 'Y' && cart.claim_prize === 'N' ?
                                    <Button circular content="Get Prize" onClick={handleClickTakePrize} disabled={mustSpin} color="orange" icon="gift"/>
                                :
                                    parseInt(cart.roulette_chance) > 0  ?
                                    <Button circular icon='redo' className="button roulette-button" onClick={handleSpinClick} content='Play Now' size="big" color="black" />
                                    :<><Label circular color="orange" basic content="Your chance is over..." size="large"/></>
                            :
                            parseInt(cart.roulette_chance) > 0 ?
                                <Button.Group size="big">
                                    <Button content="Get Prize" onClick={handleClickTakePrize} disabled={mustSpin} color="orange" circular/>
                                    <Button.Or />
                                    <Button content="Try Again" onClick={handleSpinClick} disabled={mustSpin} color="black" circular/>
                                </Button.Group>
                                :
                                <Button circular content="Get Prize" onClick={handleClickTakePrize} disabled={mustSpin} color="orange" icon="gift"/>
                    }
                </Col>
            </Row>
        </>
    )
}

export default Roulette;
