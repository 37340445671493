import React, {useEffect, useState} from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Button } from 'semantic-ui-react'
import axios from 'axios'
import {Toast} from '../../component/Toast'
import { useMyGlobal } from "../../component/GlobalData";
import { useNavigate } from 'react-router-dom'


export const PaymentSnap = ({cart, setCart, updateNotif}) => {
    const idCustomer = (localStorage.getItem('loginData')) ? JSON.parse(localStorage.getItem('loginData')).id : null
    const [btnCclState, setBtnCclState] = useState(false)
    const {token} = useMyGlobal()
    const navigate =useNavigate()
    const [url, setUrl] = useState()
    const [dsb,setDsb] = useState(false)

    const cartProduct = async (idCustomer) => {
        const param = new URLSearchParams()
        param.append('id_customer', idCustomer)

        await axios({
            method: "POST",
            url: `${global.SRV}api_hoops/controler/cart.php/?index=5`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': token
            },
            data: param
        })
            .then((response) => {
                if (response.data.state == 200) {
                    setCart(response.data.data)
                }
            })

    }


    const updateQtyInc = () => {
        let param = cart.products

        axios({
            method: "POST",
            url: `${global.SRV}api_hoops/controler/order.php/?index=15`,
            headers: {
                'Content-Type': 'text/plain',
                'Authorization': token
            },
            data: param
        })
            .then(res => {

            })
    }

    const handleCancel = (idCust) => {
        const param = new URLSearchParams()
        const idC = cart.summary.id_cart
        const idO = cart.summary.order_id
        
        param.append('id_customer', idCust)
        param.append('id_cart', idC)
        param.append('status', 'CANCEL BEFORE PAYMENT')
        param.append('status_code', '3')
        param.append('id_order', idO)
        param.append('opsi', 'cancel_snap')
        param.append('timer', 0)

        let msg = "Are you sure want to cancel this order?"
        if (window.confirm(msg) === true) {
            setBtnCclState(true)
            axios({
                method: "POST",
                url: `${global.SRV}api_hoops/controler/order.php/?index=6`,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': token
                },
                data: param
            })
                .then(response => { 
                    if (response.data.state == 200 || response.data.status_code == '200') {
                        setBtnCclState(false)
                        if (!btnCclState) {
                            updateQtyInc() // BALIKIN QTY PRODUK
                            cartProduct(idCust)
                            updateNotif(idCust)
                            navigate('/')
                            Toast.fire({
                                icon: 'success',
                                title: 'This order has been canceled...'
                            })
                        }
                    } else if(response.data.status_code == '404'){
                        setBtnCclState(false)
                        if (!btnCclState) {
                            updateQtyInc() // BALIKIN QTY PRODUK
                            cartProduct(idCust)
                            updateNotif(idCust)
                            navigate('/')
                            Toast.fire({
                                icon: 'success',
                                title: 'This order has been canceled...'
                            })
                        }
                    } else if (response.data.status_code == '412'){
                        setBtnCclState(false)
                        alert(response.data.message + ' -0')
                        console.log('1')
                        // navigate('/')
                       
                    } else {
                        setBtnCclState(false)
                        if (response.data){
                            console.log('2')
                            alert(response.data.message + ' -1')
                            // navigate('/')
                        }else{
                            alert(response.error_messages + ' -2')
                            console.log('3')
                            // navigate('/')
                        }
                    }
                })
        }
    }


    const getRedirectUrl = async (idOrder) => {
        const param = new URLSearchParams()
        param.append("id_order", idOrder)
        await axios ({
            method: "POST",
            url: `${global.SRV}api_hoops/controler/payment.php/?index=11`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': token
            },
            data: param
        })
        .then (res => {
            if (res.data.state === 200){ 
                setUrl(res.data.data.redirect_url)
            }
        })
    }
    const continuePayment = () => {  console.log(url)
        window.location.replace(url)
    }

    useEffect(() => {
        getRedirectUrl(cart.summary.order_id)
    },[cart])

  
    return (
        <Container style={{ marginTop: '10em' }}>
            <Row className='d-flex justify-content-center pt-5' style={{height:'50vh'}}>
                <Col lg={10} md={12}>
                    { cart.hasOwnProperty('is_ordered') && cart.products.length > 0 ?
                    <Button.Group size='large'>
                    <Button icon="play"  content="Continue Payment" onClick={() => continuePayment()} color='orange'/>
                    <Button.Or/>
                    <Button icon="cancel" content="Cancel Payment" loading={btnCclState} onClick={() => handleCancel(idCustomer)} disabled={btnCclState}/>
                    </Button.Group>
                    : <>No Waiting payment, please go back.</>
                    }
                    <div className='mt-5'>
                        <p>If you cancel payment, the items in your cart will be deleted and order will be canceled but you can place order again, or you want to continue payment, we will redirect you to the payment page.</p>
                        <p>If payment has expired you can cancel that payment/order, so you can place order again.</p>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}