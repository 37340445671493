import React from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col"
import { Button } from "semantic-ui-react";
import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useMyGlobal } from "./GlobalData";


const Signup = ({setShowModal}) => {
    const [firstName, setFirstName] = useState('')
    const [lastName, setlastName] = useState('')
    const [dob, setDob] = useState('')
    const [gender, setGender] = useState('')
    const [customerEmail, setEmail] = useState('')
    const [pwd, setPwd] = useState('')
    const [cfmPwd, setCfmPwd] = useState('')
    const [isSubscribe, setIsSubscribe] = useState(false)
    const navigate = useNavigate()
    const {token} = useMyGlobal()

    const setVerificationEmail = (email) => {

        axios({
            method: 'POST',
            url: `${global.SRV}api_hoops/controler/signup.php/?index=2`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': token
            },
            data: email
        })
            .then((response) => {
                
            })
    }

    const submitNewCustomer = (e) => {
        e.preventDefault()
        const param = new URLSearchParams()

        param.append('firstName', firstName)
        param.append('lastName', lastName)
        param.append('dob', dob)
        param.append('gender', gender)
        param.append('email', customerEmail)
        param.append('avatar', '')
        param.append('pwd', pwd)
        param.append('page', '../email/emailVerification.html')
        param.append('is_sub', isSubscribe ? 'Y' : 'N')

        if (pwd === cfmPwd){
            axios({
                method: 'POST',
                url: `${global.SRV}api_hoops/controler/signup.php/?index=1`,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization' : token
                },
                data: param
            })
                .then((response) => {                    console.log(response.data)
                    if (response.data.state === 200) {
                        setVerificationEmail(param)
                        setShowModal(false)
                        navigate("/verification/verificationEmail", {state:{data : response.data}})
                    } else if (response.data.state === 300){
                        setVerificationEmail(param)
                        setShowModal(false)
                        navigate("/verification/verificationEmail", {state:{data : response.data, msg:"Your email has been registered, continue to verify.. \n please check your email on inbox or spam folder for lates verification code."}})
                    } else if (response.data.state === 310){
                        alert("DOB is invalid..")
                    }
                });
        }else{
            alert('Inconsisten password')
        }
    }

    return (
        <section>
            <Form onSubmit={(e) => submitNewCustomer(e)}>
                <Row>
                    <Col sm={12} md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control type="text" placeholder="Your first name" onChange={(e) => setFirstName(e.target.value)} minLength={2} maxLength={25} required />
                        </Form.Group>
                    </Col>
                    <Col sm={12} md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control type="text" placeholder="Your last name" onChange={(e) => setlastName(e.target.value)} minLength={2} maxLength={25} required />
                        </Form.Group>
                    </Col>
                    <Col sm={12} md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>Date of Birth</Form.Label>
                            <Form.Control type="date" onChange={(e) => setDob(e.target.value)} required />
                        </Form.Group>
                    </Col>
                    <Col sm={12} md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>Gender</Form.Label>
                            <Form.Select onChange={(e) => setGender(e.target.value)} required>
                                <option value=''>Select Gender</option>
                                <option value="M">Male</option>
                                <option value="F">Female</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col sm={12} md={6}>
                        <Form.Group className="mb-3" >
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email" placeholder="Your valid email" onChange={(e) => setEmail(e.target.value)} required></Form.Control>
                            <Form.Text className="text-muted">This email will be used for login</Form.Text>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12} md={6}>
                        <Form.Group className="mb-3" >
                            <Form.Label>Set Password</Form.Label>
                            <Form.Control type="password" placeholder="Your password" onChange={(e) => setPwd(e.target.value)} minLength={8} maxLength={30} required></Form.Control>
                            <Form.Text className="text-muted">8 char's minimum</Form.Text>
                        </Form.Group>
                    </Col>
                    <Col sm={12} md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>Confirm Your Password</Form.Label>
                            <Form.Control type="password" placeholder="Must be the same" onChange={(e) => setCfmPwd(e.target.value)} minLength={8} maxLength={30} required></Form.Control>
                            <Form.Text className="text-muted">Must be the same as the password set</Form.Text>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Check checked={isSubscribe} label="Subscribe our newsletter" className="text-muted" onClick={() => setIsSubscribe(!isSubscribe)}/>
                    </Col>
                </Row>
                <hr/>
                <Row className="mt-2 footer">
                    <Col>
                        <Button
                            content='Register'
                            size='large'
                            floated='right'
                            color="black"
                            icon="arrow alternate circle right"
                            labelPosition="right"
                        />
                    </Col>
                </Row>
            </Form>

        </section>
    )

}

export default Signup;