import axios from "axios";
import React, { useEffect, useState } from "react";
import { Col, Container, Modal, Row, Stack } from "react-bootstrap";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { Form, Icon, Button } from "semantic-ui-react";
import { useMyGlobal } from "../../component/GlobalData";
import Login from "../../component/Login";


const ResetPasswordForm = () => {
    const [qstr, setQstr] = useSearchParams()
    const [valid, setValid] = useState(false)
    const [customer, setCustomer] = useState(null)
    const [pwd, setPwd] = useState('')
    const [pwdC, setPwdC] = useState('')
    const [isSuccess, setIsSuccess]= useState(false)
    const {token} = useMyGlobal()

    const handleSubmit = () => {
        const param = new URLSearchParams()

        if (pwd === pwdC){
            param.append('pwd', pwd)
            param.append('email', customer.email)
            param.append('id_customer', customer.id_customer)

            axios({
                method: "POST",
                url: `${global.SRV}api_hoops/controler/login.php/?index=5`,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': token
                },
                data: param
            })
            .then (res => {
                if (res.data.state === 200){
                    setValid(false)
                    setIsSuccess(true)
                }
            })
        } else {
            alert("Password not match")
        }
    }
    
    useEffect(() => {
        const validator = async (data) => {
            const param = new URLSearchParams()
            
            param.append('id_customer', data.get('id'))
            param.append('token', data.get('token'))
    
            await axios ({
                method: "POST",
                url: `${global.SRV}api_hoops/controler/login.php/?index=4`,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': token
                },
                data: param
            })
            .then (res => { 
                if (res.data.state === 200){
                    setValid(true)
                    setCustomer(res.data.data[0])
                }
            })
        }
        validator(qstr)
    }, [qstr])

    return (
        <>
            <section style={{ marginTop: "15rem", minHeight: "100vh", height: "100%" }}>
                <Container>
                    <Row className="d-flex justify-content-center mb-3">
                        <Col xs={12} sm={10} md={6} lg={4} xl={4}>
                        {valid ?
                        <>
                            <p className="fw-bold fs-5 text-start">Hallo, {customer.first_name + ' ' + customer.last_name}</p>
                            <p className="text-start">You asked to reset your password, if it is true that you asked for it, please set a new password, otherwise ignore this.</p>
                            <div>
                                <Form onSubmit={handleSubmit}>
                                    <Form.Input 
                                        type="password" 
                                        label="New Password" 
                                        className="text-start" 
                                        minLength="8" 
                                        defaultValue = {pwd}
                                        onChange={(e) => setPwd(e.target.value)}
                                    />
                                    <Form.Input 
                                        type="password" 
                                        label="Confirm New Password" 
                                        className="text-start" 
                                        minLength="8" 
                                        onChange={(e) => setPwdC(e.target.value)}
                                    />
                                    <Form.Button className="mt-4" content="Reset Password" fluid/>
                                </Form>
                            </div>
                        </>
                        : 
                        isSuccess ? 
                            <div>
                                <Stack direction="vertical" gap={3}>
                                    <Icon name="check circle outline" className="text-start fs-3"/>
                                    <div className="fs-5 text-start fw-bold">Password has been reset.</div> 
                                    <div className="text-start">
                                        <p>Now you can try login again using the new password.</p>
                                    </div>
                                </Stack>
                            </div>
                            : 
                            <div className="fs-4">Invalid token ...</div>
                        }
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default ResetPasswordForm