import axios from "axios";
import React, { useEffect, useState } from "react";
import { Card, Carousel, Col, Container, Image, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Divider, Label } from "semantic-ui-react";
import { useMyGlobal } from "./GlobalData";
import { FaPeopleGroup } from "react-icons/fa6";

export const PlayerCategory = () => {
    const navigate = useNavigate()
    const { token, windowWidth } = useMyGlobal()
    const [categoryData, setCategoryData] = useState([])
    const groupedData = []
    const itemsPerCarousel = windowWidth < 600 ? 3 : 6;

    for (let i = 0; i < categoryData.length; i += itemsPerCarousel) {
        groupedData.push(categoryData.slice(i, i + itemsPerCarousel));
    }

    const getCategory = async () => {
        await axios({
            method: "GET",
            url: `${global.ERP}api_web/controler/category.php/?index=6`,
            // url: "https://hoopsindonesia.co.id/api_hoops/controler/category.php/?index=6",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': token
            },
        })
            .then(res => {
                setCategoryData(res.data)
            })
    }

    const handleClickChild = (parent, childId) => {
        let data = parent.find(i => i.id_category_child == childId)
        data.from = 'child'
        data.selected_child = childId
        navigate(`/product_list/${childId}`, { state: data })
    }

    useEffect(() => {
        getCategory() 
    }, [])
    
    return (
        <Container fluid className="mt-5 mb-4">
            <React.Fragment>
                <Row>
                    <Col md={12} className="text-start">
                        <div className="text-center">
                            <FaPeopleGroup className="fs-2"/>
                            <Divider horizontal>Signature Player</Divider>
                            {/* <Label content="Signature Player" icon="tags" className="fs-5 mb-3" basic color="black" /> */}
                        </div>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Carousel keyboard={true} variant="dark" indicators={true} touch={true} controls={false}>
                        {
                            groupedData.map((group, g) => (
                                <Carousel.Item key={g}>
                                    <Row>
                                        {
                                            group.map((item, i) => (
                                                <Col lg={2} md={2} sm={2} xs={windowWidth < 600 ? 4 : 2} className="mb-3 pb-4">
                                                    <Card onClick={() => handleClickChild(group, item.id_category_child)} style={{cursor:'pointer', alignContent:'center'}}>
                                                        <Image rounded src={item.thumbnail_image ? item.thumbnail_image : 'https://via.placeholder.com/350x600.png'} fluid />
                                                    </Card>
                                                </Col>
                                            ))
                                        }
                                    </Row>
                                </Carousel.Item>
                            ))
                        }
                    </Carousel>
                </Row>
            </React.Fragment>
        </Container>
    )
}