import React, { useState, useEffect, Fragment } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Form, Row, Col, Container } from "react-bootstrap"
import { Button } from "semantic-ui-react"
import axios from "axios";
import { useMyGlobal } from "../../component/GlobalData";

const SignupGoogle = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const [dob, setDob] = useState('')
    const [gender, setGender] = useState('M')
    const {token} = useMyGlobal()

    const setVerificationEmail = (data) => {
        axios({
            method: 'POST',
            url: `${global.SRV}api_hoops/controler/signup.php/?index=2`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization':token
            },
            data: data
        })
            .then((response) => {
            })
    }


    const handleSubmit = (e,data) => {
        e.preventDefault()
        const param = new FormData()
        param.append("dob", dob)
        param.append("gender", gender)
        param.append("email", data.email)
        param.append("firstName", data.given_name)
        param.append("lastName", data.family_name)
        param.append("avatar", data.picture)
        param.append("pwd", "googlepwd")
        param.append("g_acc", "Y")
        param.append('page', '../email/emailVerification.html')

        axios({
            method:"POST",
            url : `${global.SRV}api_hoops/controler/signup.php/?index=4`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': token
            },
            data : param
        })
        .then(res => {  
            let data = res.data
            data.g_acc = true
            if (data.state === 310){
                alert ("It seems you gave the wrong date of birth, please check again")
            }else if (data.state === 300){
                alert("The email that you registered has already been registered")
            }else if (data.state === 200){
                setVerificationEmail(param)
                navigate("/verification/verificationEmail", {state:{data : data}})
            }
        })
    }


    
    return (
        <section style={{ height: '100vh', marginTop:'22vh' }}>
        { location.state ?
        <Container fluid>
            <Row className="d-flex justify-content-center mt-4">
                <Col sm={12} md={6} lg={6} xl={4} className="mb-5">
                    <div className="fs-2 mb-3">Hello, {location.state.name}</div>
                    <div>The email account <span className="fw-bold">{location.state.email}</span> you entered has not been registered</div>
                    <div className="">One more step you can log in using your google account, we need some information from you, please complete it</div>
                </Col>
            </Row>
            <Row className="d-flex justify-content-center mt-4">
                <Col sm={12} md={6} lg={6} xl={4}>
                    <Form onSubmit={(e)=>handleSubmit(e,location.state)}>
                        <Row className="d-flex justify-content-start">
                            <Col md={6} className="mb-3" >
                                <Form.Group className="text-start">
                                    <Form.Label>Date of Birth</Form.Label>
                                    <Form.Control type="date" onChange={(e) => setDob(e.target.value)} required/>
                                </Form.Group>
                            </Col>
                            <Col md={6} className="mb-3">
                                <Form.Group  className="text-start">
                                    <Form.Label>Gender</Form.Label>
                                    <Form.Select defaultValue={gender} onChange={(e) => setGender(e.target.value)} required>
                                        <option value="M">Male</option>
                                        <option value="F">Female</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col md={12} className="text-start"><small><i>This information cannot be changed at a later time</i></small></Col>
                            <Col md={12} className="mb-3 mt-5">
                                <Form.Group  className="text-start">
                                    <Form.Check type="checkbox" label="The information I have provided is completely valid" required/>
                                </Form.Group>
                            </Col>
                            <Col md={6}className="mb-3 mt-5">
                                <Button content="Rigister Now" fluid/>                               
                            </Col>
                            <Col md={6}className="mb-3 mt-5">
                                <Button content="Cancel" fluid/>                               
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </Container> 
        :
        <Container>
            <Row className="d-flex justify-content-center">
                <Col md={6} className="fs-5">
                    You'r lost, <span className="text-primary" style={{cursor:'pointer'}} onClick={() => navigate('/')}>get back</span>
                </Col>
            </Row>
        </Container>
        }
        </section>
    )
}

export default SignupGoogle