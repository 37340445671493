import React, { useEffect, useState } from "react";
import axios from "axios";
import { Carousel, Image } from "react-bootstrap";
import { useMyGlobal } from "./GlobalData";
import { useNavigate } from "react-router-dom";


const CarouselBanner = ({maintenance}) => {
    const navigate = useNavigate()
    const [carousel, setCarousel] = useState([])
    const { token, categoryAll, windowWidth, isPotrait } = useMyGlobal()
    const { idCustomer } = useMyGlobal()

    const carouselImage = async () => {
        const url = `${global.SRV}api_hoops/controler/banner_carousel.php/?index=1`;
        await axios({
            method: "GET",
            url: url,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': token,
            },
        })
            .then(res => {
                if (res.data.state === 200) {
                    setCarousel(res.data.data)
                }
            })
    }

    const updateClicked = (data) => {
        axios({
            method: "POST",
            url: `${global.SRV}api_hoops/controler/banner_carousel.php/?index=2`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token,
            },
            data: data
        })
    }

    const handleClick = (url, type, data) => {
        updateClicked(data)
        if (url) {
            if (type === 'internal') {
                let part = url.split('/').filter(Boolean)
                let idCategoryChild = part[part.length - 1]
                let selectedCategoryData = categoryAll.find(category =>
                    category.child.some(child => child.id_category_child == idCategoryChild)
                )

                selectedCategoryData.from = 'child'
                selectedCategoryData.selected_child = idCategoryChild
                navigate(`${url}`, { state: selectedCategoryData })
            }
            if (type === 'external') {
                window.location.href = url
            }
        }
    }

    useEffect(() => {
        carouselImage()
    }, [])

    return (
        <Carousel style={windowWidth > 1000 && !isPotrait ? { marginTop: '4.5%' } : { marginTop: '5em' }} >
            {
                carousel.length > 0 ?
                    carousel.map((item, idx) => {
                        const url = item.external_url ? item.external_url : item.url
                        const type = item.external_url ? "external" : "internal"
                        return (
                            <Carousel.Item interval={2000} key={idx} onClick={() => handleClick(url, type, item)} style={{ cursor: 'pointer' }}>
                                <Image
                                    className="d-block w-100 img-fluid"
                                    src={item.image}
                                    alt={item.title}
                                    fluid
                                />
                                <Carousel.Caption>
                                    <h3>{item.title}</h3>
                                    <p>{item.desc}</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                        )
                    })
                    :
                    <Carousel.Item interval={2000} key={0} style={{ cursor: 'pointer' }}>
                        <Image
                            className="d-block w-100 img-fluid"
                            src="https://via.placeholder.com/1920x720.png"
                            fluid
                        />
                    </Carousel.Item>
            }
        </Carousel>
    )
}
export default CarouselBanner;