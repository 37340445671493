import React from "react";
import { useState } from "react";
import { Button, Icon, Label } from "semantic-ui-react";
import { Accordion, Row, Col, Form, InputGroup, Offcanvas } from "react-bootstrap";
import axios from "axios";
import { useEffect } from "react";
import { TwitterPicker } from "react-color";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { useMyGlobal } from "./GlobalData";
import { Toast } from './Toast';

const ProductFilter = ({ setProducts, categoryId, categoryFrom, colorList, brandList,sizeList, doGetProduct }) => {
    const [prodName, setProdName] = useState('')
    const [priceMin, setPriceMin] = useState(0)
    const [priceMax, setPriceMax] = useState(0)
    const [mainColors, setMainColors] = useState([])
    const [showPicker, setShowPicker] = useState(false)
    const [selColor, setSelColor] = useState('')
    const idCustomer = (localStorage.getItem('loginData') !== null) ? JSON.parse(localStorage.getItem('loginData')).id : null
    const { token } = useMyGlobal()
    const [allData, setAllData] = useState([])
    const [loading, setLoading] = useState(false)
    const [btnDisable, setbtnDisable] = useState(false)
    const [brand, setBrand] = useState(0)
    const [size, setSize] = useState(0)
    const [canvasShow, setCanvasShow] = useState(false)

    const styles = {
        color: {
            width: '36px',
            height: '16px',
            borderRadius: '2px',
            background: `${selColor}`,
        },
        swatch: {
            padding: '8.5px',
            background: '#fff',
            borderRadius: '3px',
            boxShadow: '0 0 0 1px rgba(0,0,0,.2)',
            display: 'inline-flex',
            cursor: 'pointer',
        },
        popover: {
            position: 'absolute',
            zIndex: '2',
        },
        cover: {
            position: 'fixed',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
        },
    }

    const handleClick = (e) => {
        e.preventDefault()
        setShowPicker(!showPicker)
    }

    const handleClose = () => (setShowPicker(false))

    const handleChange = (color, event) => (setSelColor(color.hex))

    const handleBrandChange = (e) => {
        setBrand(e.target.value)
    }

    const handleSizeChange = (e) => {
        setSize(e.target.value)
    }

    const handleClear = () => {
        setBrand(0)
        setSize(0)
        setProdName('')
        setSelColor('')
        setPriceMin(0)
        setPriceMax(0)
        setProducts(allData)
    }
    
    const getProductList = async (id_category, id_customer, option, input) => {
        const param = new URLSearchParams()
        if (priceMin > 0 && priceMax == 0) {
            Toast.fire({
                icon: 'warning',
                text: "If you fill in the minimum price, please also fill in the maximum price",
                position: "center"
            })
        } else {
            setLoading(true)
            setbtnDisable(true)
            if (id_customer === null) { id_customer = false }

            param.append('id_category', id_category)
            param.append('id_customer', id_customer)
            param.append('option', option)

            await axios({
                method: "POST",
                url: `${global.ERP}api_web/controler/product.php?index=1`,
                // url: "https://hoopsindonesia.co.id/api_hoops/controler/product.php?index=1",
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': token
                },
                data: param
            })
                .then((response) => { 
                    if (response.data.state === 200) {
                        const data = response.data.data
        
                        const inputStr = input.toString().toLowerCase()
                        const byColor = data.filter(i => i.color.some(j => j.color_main.toString().toLowerCase() === selColor))
                        const byPrice = data.filter(i => i.final_price >= priceMin && i.final_price <= priceMax)
                        const byBrand = data.filter(i => i.category_parent.some(c => c.id_category === brand))
                        const byBrandChild = data.filter(i => i.category_child.some(c => c.id_category_child === brand))

                        setAllData(data)

                        if (response.data.state == 200) {
                            let product = data

                            if (brand !== 0 && prodName !== '' && size !== 0 && selColor !== '' && priceMin > 0 && priceMax > 0) {
                                product = categoryFrom !== 'parent' ? byBrand : byBrandChild
                                product = product.filter(i => i.product_name.toString().toLowerCase().includes(inputStr))
                                product = product.filter(i => i.size.some(c => c === size))
                                product = product.filter(i => i.color.some(j => j.color_main.toString().toLowerCase() === selColor))
                                product = product.filter(i => i.final_price >= priceMin && i.final_price <= priceMax)
                            } else if (brand !== 0 && prodName !== '' && size !== 0 && selColor !== '' && priceMin > 0 && priceMax == 0) {
                                product = categoryFrom !== 'parent' ? byBrand : byBrandChild
                                product = product.filter(i => i.product_name.toString().toLowerCase().includes(inputStr))
                                product = product.filter(i => i.size.some(c => c === size))
                                product = product.filter(i => i.color.some(j => j.color_main.toString().toLowerCase() === selColor))
                                product = product.filter(i => i.final_price >= priceMin && i.final_price <= priceMax)
                            } else if (brand !== 0 && prodName !== '' && size !== 0 && selColor !== '' && priceMin == 0 && priceMax > 0) {
                                product = categoryFrom !== 'parent' ? byBrand : byBrandChild
                                product = product.filter(i => i.product_name.toString().toLowerCase().includes(inputStr))
                                product = product.filter(i => i.size.some(c => c === size))
                                product = product.filter(i => i.color.some(j => j.color_main.toString().toLowerCase() === selColor))
                                product = product.filter(i => i.final_price >= priceMin && i.final_price <= priceMax)
                            } else if (brand !== 0 && prodName !== '' && size !== 0 && selColor !== '' && priceMin == 0 && priceMax == 0) {
                                product = categoryFrom !== 'parent' ? byBrand : byBrandChild
                                product = product.filter(i => i.product_name.toString().toLowerCase().includes(inputStr))
                                product = product.filter(i => i.size.some(c => c === size))
                                product = product.filter(i => i.color.some(j => j.color_main.toString().toLowerCase() === selColor))
                            } else if (brand !== 0 && prodName !== '' && size !== 0 && selColor == '' && priceMin > 0 && priceMax > 0) {
                                product = categoryFrom !== 'parent' ? byBrand : byBrandChild
                                product = product.filter(i => i.product_name.toString().toLowerCase().includes(inputStr))
                                product = product.filter(i => i.size.some(c => c === size))
                                product = product.filter(i => i.final_price >= priceMin && i.final_price <= priceMax)
                            } else if (brand !== 0 && prodName !== ''  && size !== 0 && selColor == '' && priceMin > 0 && priceMax == 0) {
                                product = categoryFrom !== 'parent' ? byBrand : byBrandChild
                                product = product.filter(i => i.product_name.toString().toLowerCase().includes(inputStr))
                                product = product.filter(i => i.size.some(c => c === size))
                                product = product.filter(i => i.final_price >= priceMin && i.final_price <= priceMax)
                            } else if (brand !== 0 && prodName !== ''  && size !== 0 && selColor == '' && priceMin == 0 && priceMax > 0) {
                                product = categoryFrom !== 'parent' ? byBrand : byBrandChild
                                product = product.filter(i => i.product_name.toString().toLowerCase().includes(inputStr))
                                product = product.filter(i => i.size.some(c => c === size))
                                product = product.filter(i => i.final_price >= priceMin && i.final_price <= priceMax)
                            } else if (brand !== 0 && prodName !== ''  && size == 0 && selColor == '' && priceMin == 0 && priceMax == 0) {
                                product = categoryFrom !== 'parent' ? byBrand : byBrandChild
                                product = product.filter(i => i.product_name.toString().toLowerCase().includes(inputStr))
                            } else if (brand !== 0 && prodName == '' && size == 0 && selColor == '' && priceMin == 0 && priceMax == 0) {
                                product = categoryFrom !== 'parent' ? byBrand : byBrandChild
                            } else if (brand !== 0 && prodName == '' && size == 0 && selColor !== '' && priceMin == 0 && priceMax == 0) {
                                product = categoryFrom !== 'parent' ? byBrand : byBrandChild
                                product = byColor
                            } else if (brand !== 0 && prodName == '' && size == 0 && selColor == '' && priceMin > 0 && priceMax == 0) {
                                product = categoryFrom !== 'parent' ? byBrand : byBrandChild
                                product = byPrice
                            } else if (brand !== 0 && prodName == '' && size !== 0 && selColor == '' && priceMin == 0 && priceMax == 0) {
                                product = categoryFrom !== 'parent' ? byBrand : byBrandChild
                                product = product.filter(i => i.size.some(c => c === size))
                            } else if (brand == 0 && prodName == '' && selColor !== '' && priceMin > 0 && priceMax > 0) {
                                product = product.filter(i => i.color.some(j => j.color_main.toString().toLowerCase() === selColor))
                                product = product.filter(i => i.final_price >= priceMin && i.final_price <= priceMax)
                            } else if (brand == 0 && prodName == '' && selColor !== '' && priceMin > 0 && priceMax == 0) {
                                product = product.filter(i => i.color.some(j => j.color_main.toString().toLowerCase() === selColor))
                                product = product.filter(i => i.final_price >= priceMin && i.final_price <= priceMax)
                            } else if (brand == 0 && prodName == '' && selColor !== '' && priceMin == 0 && priceMax > 0) {
                                product = product.filter(i => i.color.some(j => j.color_main.toString().toLowerCase() === selColor))
                                product = product.filter(i => i.final_price >= priceMin && i.final_price <= priceMax)
                            } else if (brand == 0 && prodName == '' && selColor !== '' && priceMin == 0 && priceMax == 0) {
                                product = byColor
                                product = product.filter(i => i.color.some(j => j.color_main.toString().toLowerCase() === selColor))
                            } else if (brand == 0 && prodName == '' && selColor == '' && priceMin > 0 && priceMax > 0) {
                                product = byPrice
                            } else if (brand == 0 && prodName == '' && selColor == '' && priceMin == 0 && priceMax > 0) {
                                product = byPrice
                            } else if (brand == 0 && prodName == '' && selColor == '' && priceMin > 0 && priceMax == 0) {
                                product = byPrice
                            } else {
                                product = data
                            }
                            if (setProducts !== null) {
                                if (product.length > 0) {
                                    setProducts(product)
                                    setLoading(false)
                                    setbtnDisable(false)
                                } else {
                                    Toast.fire({
                                        icon: "info",
                                        text: "Item not found",
                                        position: "center"
                                    })
                                    setLoading(false)
                                    setbtnDisable(false)
                                }
                            }
                        }
                    }
                })
        }
    }

    const colorOpt = (colors) => {
        let arr = []
        colors.map(i => (arr.indexOf(i.color_main) === -1) ? arr.push(i.color_main) : false)
        setMainColors(arr)
    }

    const handleCloseCanvas = () => setCanvasShow(false)
    const handleClickCanvas = () => setCanvasShow(!canvasShow)

    // const draw = (context) => {
    //     colorList.map(i => {
    //         context.fillStyle = i.color_main;
    //         context.fillRect(0, 0, 30, 30);

    //         context.fillStyle = i.color_secondary;
    //         context.fillRect(30, 0, 30, 30);

    //         context.fillStyle = i.color_accent;
    //         context.fillRect(60, 0, 30, 30);
    //     })
    // };

    useEffect(() => {
        doGetProduct && getProductList(categoryId, idCustomer, categoryFrom, prodName)
        colorOpt(colorList)
    }, [colorList])

   
    return (
        <section>
            <Row>
                <Col className="text-end">
                    <Button content="Product Filter" icon="filter" onClick={handleClickCanvas} basic color="black" className="text-right"/>
                </Col>
            </Row>
            <Offcanvas show={canvasShow} onhide={handleCloseCanvas}>
                <Offcanvas.Header>
                    <Offcanvas.Title>Product Filter</Offcanvas.Title>
                    <Icon name="close" onClick={handleClickCanvas} size="large" style={{cursor:'pointer'}}/>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Form onSubmit={(e) => {
                        e.preventDefault()
                        if (brand !== 0 || prodName !== '' || size !== 0 || selColor !== '' || priceMin > 0 || (priceMax > 0 && priceMax > priceMin)) {
                            getProductList(categoryId, idCustomer, categoryFrom, prodName)
                        } else {
                            Toast.fire({
                                icon: "warning",
                                text: "Please fill in the parameters for us to search for",
                                position: "center"
                            })
                        }
                    }}>
                        <Row>
                            <Col xl={12} className="mb-3">
                                <Form.Label className="fw-bold">Brand</Form.Label>
                                <Form.Select onChange={handleBrandChange}>
                                    <option value={0}>Select...</option>
                                    {
                                        brandList.map((item, i) => {
                                            const words = item.category_name.split(' ')
                                            const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())

                                            return (
                                                <option key={i} value={item.id_category}>{capitalizedWords.join(' ')}</option>
                                            )
                                        })
                                    }
                                </Form.Select>
                            </Col>
                            <Col xl={12} className="mb-3">
                                <Form.Label className="fw-bold">Product name</Form.Label>
                                <Form.Control type="text" value={prodName} onChange={(e) => setProdName(e.target.value)} placeholder="Type here" />
                            </Col>
                            <Col xl={12} className="mb-3">
                                <Form.Label className="fw-bold">Size</Form.Label>
                                <Form.Select onChange={handleSizeChange}>
                                    <option value={0}>Select...</option>
                                    {
                                        sizeList.map((item, i) => (
                                            <option key={i} value={item}>{item}</option>
                                        ))
                                    }
                                </Form.Select>
                            </Col>
                            <Col xl={12} className="mb-3">
                                <div className="mb-2 fw-bold">Color</div>
                                <InputGroup>
                                    <div style={styles.swatch} >
                                        <div style={styles.color} onClick={(e) => handleClick(e)} />
                                        {
                                            (selColor !== '') ?
                                                <FontAwesomeIcon icon={faClose} className="ms-2" onClick={() => setSelColor('')} />
                                                : <></>
                                        }
                                    </div>

                                </InputGroup>
                                {showPicker ?
                                    <div style={styles.popover}>
                                        <div style={styles.cover} onClick={handleClose} />
                                        <TwitterPicker colors={mainColors} color={selColor} onChange={handleChange} onChangeComplete={handleClose} />
                                    </div> : null}
                            </Col>
                            <Col xl={12} className="mb-3">
                                <Form.Label className="fw-bold">Price Range (Rp)</Form.Label>
                                <InputGroup>
                                    <InputGroup.Text>From</InputGroup.Text>
                                    <Form.Control type="text" value={priceMin} minLength={0} onChange={(e) => setPriceMin(e.target.value)} placeholder="Min" />
                                    <InputGroup.Text>To</InputGroup.Text>
                                    <Form.Control type="text" value={priceMax} maxLength={5000000} onChange={(e) => setPriceMax(e.target.value)} placeholder="Max" />
                                </InputGroup>
                            </Col>
                            <Col xl={12} className="d-flex justify-content-end align-self-end mb-3 mt-5">
                                <Button.Group floated="Left" fluid>
                                    <Button content="Apply" icon="filter" type="submit" loading={loading} disabled={btnDisable} color="orange" />
                                    <Button content="Clear" type="reset" icon="retweet" onClick={() => handleClear()} disabled={btnDisable} color="black" />
                                </Button.Group>
                            </Col>
                        </Row>
                    </Form>
                </Offcanvas.Body>
            </Offcanvas>
            {/* <Accordion>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Product Filter</Accordion.Header>
                    <Accordion.Body>
                        <Form onSubmit={(e) => {
                            e.preventDefault()
                            if (brand !== 0 || prodName !== '' || size !== 0 || selColor !== '' || priceMin > 0 || (priceMax > 0 && priceMax > priceMin)) {
                                getProductList(categoryId, idCustomer, categoryFrom, prodName)
                            } else {
                                Toast.fire({
                                    icon: "warning",
                                    text: "Please fill in the parameters for us to search for",
                                    position: "center"
                                })
                            }
                        }}>
                            <Row>
                                <Col xl={2} className="mb-3">
                                    <Form.Label>Brand</Form.Label>
                                    <Form.Select onChange={handleBrandChange}>
                                        <option value={0}>Select...</option>
                                        {
                                            brandList.map((item, i) => (
                                                <option key={i} value={item.id_category}>{item.category_name}</option>
                                            ))
                                        }
                                    </Form.Select>
                                </Col>
                                <Col xl={2} className="mb-3">
                                    <Form.Label>Product name</Form.Label>
                                    <Form.Control type="text" value={prodName} onChange={(e) => setProdName(e.target.value)} placeholder="Type here" />
                                </Col>
                                <Col xl={1} className="mb-3">
                                    <Form.Label>Size</Form.Label>
                                    <Form.Select onChange={handleSizeChange}>
                                        <option value={0}>Select...</option>
                                        {
                                            sizeList.map((item, i) => (
                                                <option key={i} value={item}>{item}</option>
                                            ))
                                        }
                                    </Form.Select>
                                </Col>
                                <Col xl={1} className="mb-3">
                                    <div className="mb-2">Color</div>
                                    <InputGroup>
                                        <div style={styles.swatch} >
                                            <div style={styles.color} onClick={(e) => handleClick(e)} />
                                            {
                                                (selColor !== '') ?
                                                    <FontAwesomeIcon icon={faClose} className="ms-2" onClick={() => setSelColor('')} />
                                                    : <></>
                                            }
                                        </div>

                                    </InputGroup>
                                    {showPicker ?
                                        <div style={styles.popover}>
                                            <div style={styles.cover} onClick={handleClose} />
                                            <TwitterPicker colors={mainColors} color={selColor} onChange={handleChange} onChangeComplete={handleClose} />
                                        </div> : null}
                                </Col>
                                <Col xl={3} className="mb-3">
                                    <Form.Label>Price Range (Rp)</Form.Label>
                                    <InputGroup>
                                        <InputGroup.Text>From</InputGroup.Text>
                                        <Form.Control type="text" value={priceMin} minLength={0} onChange={(e) => setPriceMin(e.target.value)} placeholder="Min" />
                                        <InputGroup.Text>To</InputGroup.Text>
                                        <Form.Control type="text" value={priceMax} maxLength={5000000} onChange={(e) => setPriceMax(e.target.value)} placeholder="Max" />
                                    </InputGroup>
                                </Col>
                                <Col xl={3} className="d-flex justify-content-end align-self-end mb-3">
                                    <Button.Group floated="Left" color="black">
                                        <Button content="Apply" type="submit" loading={loading} disabled={btnDisable} />
                                        <Button content="Clear" type="reset" onClick={() => handleClear()} disabled={btnDisable} />
                                    </Button.Group>
                                </Col>
                            </Row>
                        </Form>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion> */}
        </section>
    )
}

export default ProductFilter;