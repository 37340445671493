import axios from "axios";
import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "semantic-ui-react";
import succesImg from "../../assets/pay2.png"
import notFoundImg from "../../assets/404.png"
import { useMyGlobal } from "../../component/GlobalData";

const PaymentSuccess = ({ cart, setCart, updateNotif }) => {
    const location = useLocation()
    const urlParam = new URLSearchParams(location.search)
    const [idOrder, setIdOrder] = useState(null)
    const navigate = useNavigate()
    const urlData = useLocation().state
    const idCustomer = (localStorage.getItem('loginData')) ? JSON.parse(localStorage.getItem('loginData')).id : null
    const [payState, setPayState] = useState(null)
    const [allow, setAllow] = useState(null)
    const {token} = useMyGlobal()

    useEffect(() => {
        const cartData = async (idCustomer) => {
            const param = new URLSearchParams()
            param.append('id_customer', idCustomer)

            await axios({
                method: "POST",
                url: `${global.SRV}api_hoops/controler/cart.php/?index=5`,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization' : token
                },
                data: param
            })
                .then((response) => {
                    if (response.data.state == 200) {
                        setCart(response.data.data)
                    }
                })
                .catch((error) => console.log(error))
        }

        cartData(idCustomer)
    }, [])

    useEffect(() => {
        (urlParam.get('id_order')) ? setIdOrder(urlParam.get('id_order')) : setIdOrder(null)

        const payment = async (idOrder) => {
            const param = new URLSearchParams()
            param.append('id_order', idOrder)
            await axios({
                method: "POST",
                url: `${global.SRV}api_hoops/controler/payment.php/?index=6`,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization' : token
                },
                data: param
            })
                .then(response => {
                    if (response.data.state === 200) {
                        setPayState(response.data.data.payment_status)
                    }
                })
        }

        if (idOrder) payment(idOrder)


    }, [idOrder])


    return (
        <>
        {
            payState === 'settlement' || payState === 'capture' ? 
            <Container>
                <Row style={{ paddingTop: "12%", minHeight: "100vh" }}>
                    <Col md={12} className>
                        <div className="fs-2 fw-bold mb-0">Your order {urlData && urlData.id_order} has been successfully paid ...</div>
                        <div><img src={succesImg} className="mb-5" /></div>
                        <Button content="Go to Transaction List" icon='right arrow' labelPosition='right' onClick={() => navigate('/customer/profile', { state: 'trxActive' })} />
                    </Col>
                    <Col md={12}>

                    </Col>
                </Row>
            </Container>
            :
            <Container>
                <Row style={{ paddingTop: "15%", minHeight: "100vh" }}>
                    <Col md={12} className>
                        <div className="fs-2 fw-bold mb-5">Your order {urlData && urlData.id_order} is {payState ? payState : 'invalid please go back'} ...</div>
                        <div><img src={notFoundImg} className="mb-5" /></div>
                        <Button content="Go to Transaction List" icon='right arrow' labelPosition='right' onClick={() => navigate('/customer/profile', { state: 'trxActive' })} />
                    </Col>
                    <Col md={12}>

                    </Col>
                </Row>
            </Container>
        }   
        </>
    )
}

export default PaymentSuccess