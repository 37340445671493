import React, { useEffect, useRef, useState } from "react";

const TimerCountDown = ({ expiryTime, setCurentTime, cancelOnExpire, sendTime }) => {
    const Ref = useRef(null);
    const [timer, setTimer] = useState("00 : 00 : 00 : 00");
    const idCustomer = localStorage.getItem("loginData")
        ? JSON.parse(localStorage.getItem("loginData")).id
        : null;

    const getTimeRemaining = (e) => {
        const total = Date.parse(e) - Date.parse(new Date());
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
        const days = Math.floor(total / (1000 * 60 * 60 * 24));

        if (sendTime) {
            setCurentTime(total);
        }

        if (total <= 0) clearInterval(Ref.current);
        return {
            total,
            days,
            hours,
            minutes,
            seconds,
        };
    };

    const startTimer = (e) => {
        let { total, days, hours, minutes, seconds } = getTimeRemaining(e);
        if (total >= 0) {
            setTimer(
                (days > 9 ? days : "0" + days) + " : " +
                (hours > 9 ? hours : "0" + hours) + " : " +
                (minutes > 9 ? minutes : "0" + minutes) + " : " +
                (seconds > 9 ? seconds : "0" + seconds)
            );
        }
    };

    const clearTimer = (e) => {
        if (Ref.current) clearInterval(Ref.current);
        const id = setInterval(() => {
            startTimer(e);
        }, 1000);
        Ref.current = id;
    };

    useEffect(() => {
        clearTimer(expiryTime);
    }, []);

    return timer;
};

export default TimerCountDown;
