import React from "react";
import axios from "axios";
import { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import AsyncSelect from 'react-select/async';
import { Button, Icon } from "semantic-ui-react";
import { useMyGlobal } from "./GlobalData";
import LZString from 'lz-string';
import dataDestinasi from '../assets/data.json'

const AddressForm = ({ showModal, getUserAddress, isFirstAddress, editData, setUserAddress, cityOption }) => {
    const [optionDestnation, setOptionDestination] = useState([])
    const [idDestination, setIdDestination] = useState('')
    const [destination, setDestination] = useState('')
    const [idCustomer, setIdCustomer] = useState('')
    const [allDestination, setAllDestination] = useState([])
    const [recipientName, setrecipientName] = useState('')
    const [celularNumber, setCelularNumber] = useState('')
    const [addressLabel, setAddressLabel] = useState('')
    const [addressDetail, setAddressDetail] = useState('')
    const [addressDetailDua, setAddressDetailDua] = useState('')
    const [zipCode, setZipCode] = useState('')
    const [isDefault, setIsDefault] = useState(false)
    const [checkBoxState, setCheckBoxState] = useState(true)
    const { token } = useMyGlobal()

    const generateDestinationOption = async () => {
        let option = []
        // await axios({
        //     method: 'GET',
        //     url: `${global.SRV}api_hoops/controler/courier.php/?index=1`,
        //     headers: {
        //         'Content-Type': 'application/x-www-form-urlencoded',
        //         'Authorization': token
        //     }
        // })
        //     .then((response) => {
        //         if (response.data.length > 0) {
        //             response.data.map((item, index) => {
        //                 option.push({
        //                     label: `${item.sub_district}, ${item.district}, ${item.province}`,
        //                     value: item.id_destination,
        //                     zip_code: item.zip_code
        //                 })
        //             })
        //         }
        //     })
        //     .catch(err => console.log(err))
        dataDestinasi.map((item, index) => {
            option.push({
                label: `${item.sub_district}, ${item.district}, ${item.city}, ${item.province}`,
                value: item.id_destination,
                zip_code: item.zip_code
            })
        })
        setOptionDestination(option)


        // GA PERLU SIMPAN LAGI DI LOCSTORAGE
        // try {
        //     // Kompresi data dan simpan di localStorage
        //     const compressedData = LZString.compressToBase64(JSON.stringify(option));
        //     localStorage.setItem('_destination', compressedData);
        // } catch (error) {
        //     console.error('Error during compression and storage:', error);
        // }
    }

    const filterDestin = (inputValue) => {
        return optionDestnation.filter((i) =>
            i.label.toLowerCase().includes(inputValue.toLowerCase())
        )
    }

    const promiseOptionsDestination = (inputValue) => (
        new Promise((resolve) => {
            setTimeout(() => {
                resolve(filterDestin(inputValue));
            }, 1000);
        })
    );

    const getZipCode = (destination) => {
        if (typeof cityOption !== 'undefined') {
            const postalCode = cityOption.filter((i) => i.value === destination).map(i => i.zip_code)
            setZipCode(postalCode[0])
        } else {
            const postalCode = optionDestnation.filter((i) => i.value === destination).map(i => i.zip_code)
            setZipCode(postalCode[0])
        }
    }


    const handleSubmit = (e, isFirstAddr) => {
        e.preventDefault()
        const param = new URLSearchParams()

        param.append('recipient_name', recipientName)
        param.append('celular_number', celularNumber)
        param.append('address_label', addressLabel)
        param.append('id_destination', idDestination)
        param.append('zip_code', zipCode)
        param.append('address_detail', addressDetail)
        param.append('address_detail_dua', addressDetailDua)
        param.append('id_customer', idCustomer)
        param.append('is_default', (isFirstAddr) ? 1 : isDefault)
        param.append('id_address', (editData) ? editData.id_address : '')

        if (editData) {
            axios({
                method: "POST",
                url: `${global.SRV}api_hoops/controler/customer.php/?index=5`,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': token
                },
                data: param
            })
                .then((response) => {
                    getUserAddress(idCustomer, 1)
                    showModal(false)
                    setUserAddress({ tes: "tessss" })
                })
        } else if (!editData) {
            if (idDestination != '' && destination != '' && zipCode != '') {
                axios({
                    method: "POST",
                    url: `${global.SRV}api_hoops/controler/customer.php/?index=2`,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': token
                    },
                    data: param
                })
                    .then((response) => {
                        getUserAddress(idCustomer, 1)
                        showModal(false)
                    })
            } else {
                alert("Please select sub district, zip code and address")
            }


        }
    }

    useEffect(() => {
        // generateDestinationOption(); 
        setIdCustomer(JSON.parse(localStorage.getItem('loginData')).id)// HARUS DI CEK ULANG TAKUTNYA AKSES ID CUSTOMER LAIN (NANTI KACAU EDIT ALAMAT ORANG)
        if (editData) {
            let d = editData
            setrecipientName(d.recipient_name)
            setCelularNumber(d.celular_number)
            setAddressLabel(d.address_label)
            setIdDestination(d.id_destination)
            setZipCode(d.zip_code)
            setAddressDetail(d.address_detail)
            setAddressDetailDua(d.address_detail_dua)
        }


        // if (localStorage.getItem('_destination') == null) {
            generateDestinationOption()
        // } else {
        //     let compressedData = localStorage.getItem('_destination');
        //     // Dekompresi data dan set ke state (menggunakan setState sebagai contoh)
        //     try {
        //         const compressedData = localStorage.getItem('_destination');
        //         const decompressedData = JSON.parse(LZString.decompressFromBase64(compressedData));
        //         setOptionDestination(decompressedData);
        //     } catch (error) {
        //         console.error('Error during decompression:', error);
        //     }
        // }

    }, [])

    return (
        <section>
            <Form onSubmit={(e) => handleSubmit(e, isFirstAddress)}>
                <Row className="mb-3">
                    <Col sm={12} md={12}>
                        <Form.Group>
                            <Form.Label>Recipient Name</Form.Label>
                            <Form.Control type="text" maxLength={22} minLength={5} placeholder="Your Complete Name" defaultValue={recipientName} onChange={(e) => setrecipientName(e.target.value)} required />
                            <Form.Text>Max character (22)</Form.Text>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col sm={12} md={12}>
                        <Form.Group>
                            <Form.Label>Celular Number</Form.Label>
                            <Form.Control type="number" maxLength={15} minLength={10} placeholder="Celular Number" defaultValue={celularNumber} onChange={(e) => setCelularNumber(e.target.value)} required />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col sm={12} md={12}>
                        <Form.Group>
                            <Form.Label>Label</Form.Label>
                            <Form.Control type="text" maxLength={15} minLength={5} placeholder="Ex : Rumah, Kantor" defaultValue={addressLabel} onChange={(e) => setAddressLabel(e.target.value)} required />
                            <Form.Text>Max character (10). This cannot be filled in with an address, this is just an address label, such as Home, Work, etc.</Form.Text>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <Form.Group>
                            <Form.Label>Subdistric (Kecamatan)</Form.Label>
                            {
                                <>
                                    <AsyncSelect
                                        isClearable
                                        cacheOptions
                                        defaultOptions
                                        // value={(editData) && { label: editData.destination, value: editData.destination }}
                                        loadOptions={promiseOptionsDestination}
                                        onChange={(value, action) => {
                                            setIdDestination((value) ? value.value : null)
                                            setDestination((value) ? value.label : null)
                                            getZipCode((value) ? value.value : null)
                                        }}
                                        defaultValue={(editData) && { label: editData.destination, value: editData.destination }}
                                        required
                                    />
                                    {
                                        optionDestnation.length == 0 &&
                                        <small className="text-primary"><Icon name="spinner" loading />Loading...</small>
                                    }
                                </>
                            }
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col sm={12} md={12}>
                        <Form.Group>
                            <Form.Label>Zip Code</Form.Label>
                            <Form.Control type="text" defaultValue={zipCode} onChange={(e) => setZipCode(e.target.value)} maxLength={5} minLength={5} required />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col sm={12} md={12}>
                        <Form.Group>
                            <Form.Label>Address Detail</Form.Label>
                            <Form.Control type="textarea" maxLength={30} minLength={10} defaultValue={addressDetail} onChange={(e) => setAddressDetail(e.target.value)} required />
                            <Form.Text>Max Character (30) If it's not enough space, you can continue in "Additional Address Detail"</Form.Text>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col sm={12} md={12}>
                        <Form.Group>
                            <Form.Label>Additional Address Detail</Form.Label>
                            <Form.Control type="text" maxLength={30} defaultValue={addressDetailDua} onChange={(e) => setAddressDetailDua(e.target.value)} />
                            <Form.Text>Max Character (30)</Form.Text>
                        </Form.Group>
                    </Col>
                </Row>

                {(!isFirstAddress) ? (
                    <Row className="mb-3">
                        <Col sm={12} md={12}>
                            <Form.Group>
                                <Form.Check
                                    type="checkbox"
                                    label="Set Default"
                                    onChange={(e) => setIsDefault((e.target.checked === true) ? 1 : 0)}
                                    defaultChecked={editData.isDefault === "1" ? true : false}
                                    // disabled={(editData.isDefault === "1" && ) ? true : false}
                                    disabled
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                ) : <></>}

                <Row>
                    <Col sm={12} md={12}>
                        <Button content="Add Address" floated="right" size="large" color="black" icon="plus"/>
                    </Col>
                </Row>
            </Form>
        </section>
    )
}

export default AddressForm;